<script>
export default {
    name: "MixinFilterableView",

    data()
    {
        return {
            filterList    : {},
            filterKey     : false,
            filtered      : [],
            matchList     : [],
            viewIsFiltered: false
        }
    },

    watch: {
        tab       : {
            immediate: true,
            handler( newValue, oldValue )
            {
                if( undefined !== oldValue
                    && false !== oldValue
                    && newValue !== oldValue )
                {
                    this.filter()
                        .then( () =>
                        {
                            this.filterKey = 'flt-' + this.$core.f().objectHash( this.filterList, true ) + '-forced'
                        } )
                }
            }
        },
        controlKey: {
            immediate: true,
            handler( newValue, oldValue )
            {
                if( false !== oldValue
                    && newValue !== oldValue
                    && 'false-false' !== newValue )
                {
                    this.$core.setState( 'tabsPrepared', false )
                    this.filter()
                        .then( () =>
                        {
                            this.filterKey = 'flt-' + this.$core.f().objectHash( this.filterList, true ) + '-forced'
                        } )
                }
            }
        }
    },

    methods: {

        setSearchFilter( keyword )
        {

            this.$core.getCoreTimer()
                .addTimeout( 'fulltext-search', 1000, () =>
                {

                    if( undefined !== keyword )
                    {

                        let lookup = keyword
                        this.setFilters( 'fulltext', [ { value: lookup, isFulltext: true } ] )

                    }
                    else
                    {
                        this.setFilters( 'fulltext', undefined )
                    }

                } )

        },

        setFilters( type, filters )
        {

            if( '_refresh' !== type )
            {

                if( undefined === filters )
                {
                    delete this.filterList[ type ]
                    this.filterList[ type ] = []
                }
                else
                {
                    this.filterList[ type ] = filters
                }

            }
            else
            {
                delete this.selected
                this.selected = {}
            }
            this.filter()
                .then( () =>
                {
                    this.filterKey = 'flt-' + this.$core.f().objectHash( this.filterList, true ) + '-' + this.$core.f().objectHash( this.prepared[ this.tab ], true )
                } )

        },

        /*eslint-disable*/
        filterMatch( filterType, localId )
        {

            try
            {

                let detailViewTabType = this.$core.getState( 'default-view-tab-type' ),
                    filters           = Array.isArray( filterType ) ? filterType : [ filterType ],
                    queue             = undefined

                if( undefined !== this.$props.overrideQueueType )
                {
                    queue = this.$core.f().lcFirst( this.$props.overrideQueueType )
                }
                else
                {
                    detailViewTabType || ( this.$props.viewItem ? this.$core.f().lcFirst( this.$props.viewItem ) : this.overrideViewItem )
                }

                if( undefined === filterType )
                {
                    return true
                }

                let indexMap = this.$core.getBaseClassHelper()
                                   .get( queue )
                                   .registry
                                   .indexMaps
                                   .get( localId )

                if( undefined === indexMap )
                {
                    return false
                }

                for( let f in filters )
                {

                    let filter = filters[ f ]

                    if( indexMap[ filter.attr ] === filter.value )
                    {
                        return true
                    }

                    if( filter.isFulltext )
                    {
                        if( -1 < indexMap.fullTextHint.toLowerCase().indexOf( filter.value.toLowerCase() ) )
                        {
                            return true
                        }
                    }

                    switch( filter.attr )
                    {
                        case 'classId':
                        case 'groupId':
                        case 'yeargroupId':
                            if( indexMap.filterBy === filter.value
                                || filter.attr === 'classId' && 'all' === filter.value )
                            {
                                return true
                            }
                            if( indexMap[ filter.attr.replace( 'Id', 'Reference' ) ] === filter.value )
                            {
                                return true
                            }
                            switch( filter.attr )
                            {
                                case 'groupId':
                                    if( indexMap[ filter.attr ]
                                        && -1 < indexMap[ filter.attr ].indexOf( filter.value ) )
                                    {
                                        return true
                                    }
                                    break
                            }
                            break
                        case 'labelId':
                            if( undefined !== indexMap.labels
                                && -1 < indexMap.labels.indexOf( filter.value ) )
                            {
                                return true
                            }
                            break
                    }

                }

            }
            catch( e )
            {
                return false
            }
            return false

        },

        filter()
        {

            return new Promise( resolve =>
            {

                this.viewIsFiltered = false
                let filtered = []

                if( this.$core.f().filterEmpty( this.filterList ) )
                {
                    this.filtered = filtered
                    return resolve()
                }

                let list = this.prepared[ this.tab ] || this.elementList

                for( let i in list )
                {

                    let match = false
                    for( let t in this.filterList )
                    {
                        if( this.filterMatch( this.filterList[ t ], list[ i ] ) )
                        {
                            match = true
                            break
                        }
                    }

                    if( match )
                    {
                        filtered.push( list[ i ] )
                    }
                }

                this.viewIsFiltered = true
                this.filtered = filtered

                return resolve()

            } )

        },


    }
}
</script>