export default class RegisterStudentAccessHelper
{

    constructor( core )
    {

        this.logger = core.getLogger()
        this.crypto = core.getCryptoCore()
        this.config = core.getConfig()
        this.cryptoHelper = core.getCryptoHelper()
        this.client = core.getClient()
        this.uuid = core.getUuid()
        this.ui = core.getUi()
        this.store = core.getStore()
        this.reformatter = core.getReformatter()
        this.eventManager = core.getEventManager()
        this.printHelper = core.getPrinting()

        this.baseClassHelper = core.getBaseClassHelper()
        if( undefined === this.baseClassHelper )
        {
            this.eventManager.append( 'on-baseclasses-available', () => {
                this.baseClassHelper = core.getBaseClassHelper()
            } )
        }

    }

    _generateUsername( student )
    {
        return student.firstname.substring( 0, 3 ).toUpperCase() + '-' +
               student.lastname.substring( 0, 3 ).toUpperCase() + '-' +
               this.crypto.generateRandomString( 8, true ).toUpperCase()
    }

    _blobToBase64( blob )
    {

        return new Promise( resolve =>
        {

            const reader = new FileReader();
            reader.onloadend = () => resolve( reader.result );
            reader.readAsDataURL( blob );

        } )

    }

    _addSpace( value )
    {
        return value.split( '' ).join( ' ' )
    }

    _addRow( tbody, left, right, addclass, isObject )
    {

        let tr      = document.createElement( 'tr' ),
            tdLeft  = document.createElement( 'td' ),
            tdRight = document.createElement( 'td' )

        if( undefined !== addclass )
        {
            tdRight.classList.add( addclass )
        }

        tdLeft.innerHTML = left
        if( !isObject )
        {
            tdRight.innerHTML = right
        }
        else
        {
            tdRight.appendChild( right )
        }
        tr.appendChild( tdLeft )
        tr.appendChild( tdRight )
        tbody.appendChild( tr )

    }

    _getQrImage( student )
    {

        let container = document.querySelector( '#access_qr_'+student.localId ),
            img = new Image

        if( null !== container )
        {
            let canvas = container.querySelector( 'canvas' )
            img.src = canvas.toDataURL()
            img.classList.add( 'image-contents' )
            return img
        }
        else
        {
            return img
        }

    }

    _createTable( student, access )
    {

        let table = document.createElement( 'table' ),
            tbody = document.createElement( 'tbody' )

        table.classList.add( 'list' )
        table.classList.add( 'hidden' )

        table.setAttribute( 'id', 'printable-access' )

        let spaced = {
            'Login'   : 'username',
            'Passwort': 'passphrase',
            'PUK'     : 'puk'
        }

        access.puk = access.keys[ 0 ].backupKey

        for( let s in spaced )
        {

            this._addRow( tbody, s, access[ spaced[ s ] ], 'font-courier' )

        }

        this._addRow( tbody, 'Login via QR-Code', this._getQrImage( student ), 'has-image', true )

        this._addRow( tbody, 'gültig ab', this.reformatter.dateFromTimestamp( access.timestamp ) )
        this._addRow( tbody, 'Adresse', 'https://app.entzettelt.de' )
        this._addRow( tbody, 'Hinweise', 'Das Passwort bitte nach der ersten Anmeldung ändern '+"\n"+'und dieses' +
                                         ' Informationsblatt an einem sicheren Ort aufbewahren.'+"\n\n"+
                                         'Mit dem PUK kann das Passwort jederzeit wiederhergestellt werden, solltest du es einmal vergessen haben.' )

        table.appendChild( tbody )

        delete access.puk

        return table

    }

    _getPdf( student, access )
    {

        return new Promise( resolve =>
        {

            setTimeout( () => {

                let table = this._createTable( student, access )

                document.body.appendChild( table )

                let fakeElm = {
                    localId  : 'access',
                    type     : 'access',
                    listname : 'entzettelt-Zugang für ' + student.firstname + ' ' + student.lastname,
                    color    : 'white',
                    timestamp: Date.now(),
                    key      : this.uuid.generate()
                }

                this.eventManager.add( 'print-ready-' + fakeElm.key, ( blob ) =>
                {
                    document.body.removeChild( table )
                    return resolve( blob )
                } )

                this.printHelper.getElementAsPdf( fakeElm )

            }, 500 )

        } )

    }

    regeneratePdf( student, access )
    {
        return new Promise( resolve =>
        {
            this._getPdf( student, access )
                .then( blob =>
                {

                    this._blobToBase64( blob )
                        .then( accessDocument =>
                        {

                            return resolve( accessDocument )

                        } )
                } )
        } )
    }

    /*eslint-disable*/
    registerStudentAccess( student, repeatCount, silent )
    {

        return new Promise( ( resolve, reject ) =>
        {

            repeatCount = repeatCount || 0
            silent = silent || false

            if( !silent )
            {
                this.ui.showBlocker( 'Augenblick bitte...', 'Der entzettelt-Zugang wird eingerichtet' )
            }

            let username = this._generateUsername( student ),
                message  = {
                    method      : 'users.checkAvailableUsername',
                    username    : username.trim(),
                    looseSession: true
                }

            this.client.request( message )
                .then( response =>
                {

                    if( 'available' === response.state )
                    {

                        let objectClass     = this.baseClassHelper.get( 'studentAccess' ),
                            passphrase      = this.crypto.generateRandomString( 16 ),
                            studentKeyPair  = this.crypto.generateKeyPair( passphrase ),
                            randomString    = this.crypto.generateRandomString( 32, true ),
                            encryptedRandom = this.crypto.encrypt( randomString, passphrase ),
                            studentAccess   = {
                                studentLocalId: student.localId,
                                username      : username,
                                passphrase    : passphrase,
                                timestamp     : Date.now(),
                                keys          : [
                                    studentKeyPair
                                ]
                            }

                        this.eventManager.dispatch( 'on-student-access-generated', studentAccess )

                        setTimeout( () => {

                            if( !silent )
                            {
                                this.ui.blockerText( 'Das Einrichtungs-PDF wird erzeugt...' )
                            }

                            this.cryptoHelper
                                .decrypt( this.store.getters.metadata, true )
                                .then( ownMeta =>
                                {

                                    let cryptedMeta = [ 'firstname', 'lastname', 'school', 'school_zipcity', 'email' ],
                                        meta        = {}

                                    for( let c in cryptedMeta )
                                    {
                                        let field = cryptedMeta[ c ]
                                        if( 'email' === field )
                                        {
                                            if( undefined !== student[ field ] )
                                            {
                                                let value = this.reformatter.url( student[ field ] )
                                                meta[ field ] = this.crypto.encryptForServer( value )
                                            }
                                            else
                                            {
                                                meta[ field ] = this.crypto.encryptForServer( '' )
                                            }
                                        }
                                        else if( 'school' === field || 'school_zipcity' === field )
                                        {
                                            meta[ field ] = this.crypto.encryptForServer( ownMeta[ field ] )
                                        }
                                        else
                                        {
                                            meta[ field ] = this.crypto.encryptForServer( student[ field ] )
                                        }
                                    }

                                    let message = {
                                        method              : 'users.registerStudentAccess',
                                        username            : studentAccess.username,
                                        deviceUuid          : this.uuid.generate(),
                                        privateKey          : studentKeyPair.private,
                                        publicKey           : studentKeyPair.public,
                                        backupKey           : studentKeyPair.backup,
                                        restoreSecret       : randomString,
                                        restoreSecretCrypted: encryptedRandom,
                                        meta                : meta,
                                        idLocal             : student.localId,
                                        idLicense           : 21,
                                        localBranch         : this.config.branch
                                    }

                                    this.eventManager.dispatchIndexed( 'on-preview-access-'+student.localId, studentAccess )
                                    console.log( 'SAC', message, studentAccess )

                                    this._getPdf( student, studentAccess )
                                        .then( blob =>
                                        {

                                            this._blobToBase64( blob )
                                                .then( accessDocument =>
                                                {

                                                    this.client
                                                        .request( message )
                                                        .then( response =>
                                                        {


                                                            studentAccess.accessDocument = accessDocument
                                                            studentAccess.deviceUuid = message.deviceUuid
                                                            studentAccess.idUser = response.result.idUser
                                                            studentAccess.idDevice = response.result.idDevice

                                                            let accessLocalId = objectClass.create( studentAccess )
                                                            this.eventManager.append( 'storable-after-update-' + accessLocalId, () =>
                                                            {

                                                                if( !silent )
                                                                {

                                                                    this.ui.hideBlocker()
                                                                    this.ui.setPageMessage( 'Zugang angelegt',
                                                                        'Der Zugang wurde erfolgreich eingerichtet und freigeschaltet.',
                                                                        'ok', true )

                                                                }

                                                                return resolve()

                                                            } )

                                                        } )
                                                        .catch( () =>
                                                        {

                                                            if( !silent )
                                                            {
                                                                this.ui.setPageMessage( 'Das hat nicht funktioniert',
                                                                    'Der Zugang konnte nicht angelegt werden: Bitte überprüfe deine Internetverbindung und versuch es in einigen Minuten noch einmal.',
                                                                    'error', true )
                                                            }
                                                            return reject()

                                                        } )

                                                } )

                                        } )
                                        .catch( () =>
                                        {

                                            this.ui.setPageMessage( 'Das hat nicht funktioniert',
                                                'Das Einrichtungsdokument konnte nicht erzeugt werden.',
                                                'error', true )
                                            return reject()

                                        } )

                                } )

                        }, 2000 )

                    }
                    else
                    {
                        if( 5 > repeatCount )
                        {
                            repeatCount++
                            setTimeout( () =>
                            {
                                return resolve( this.registerStudentAccess( student, repeatCount, silent ) )
                            }, 500 )
                        }
                        else
                        {

                            if( !silent )
                            {
                                this.ui.hideBlocker()
                                this.ui.setPageMessage( 'Das hat nicht funktioniert',
                                    'Aktuell kann kein Zugang angelegt werden: Bitte überprüfe deine Internetverbindung und versuch es in einigen Minuten noch einmal.',
                                    'error', true )
                            }
                            return reject()

                        }
                    }
                } )

        } )

    }

}