import SharedMediaSync from "@/classes/Core/Share/core/SharedMediaSync";

export default class ShareSync
{

    constructor( parent )
    {

        if( !ShareSync.instance )
        {

            this.parent = parent
            this.logSign = 'Core::Share [SHR]::ShareSync:'

            this.sharedMediaSync = new SharedMediaSync( this.parent, this )

            this.lastHash = false
            this.lastRemoteHash = false
            this.remoteKeysFetched = false
            this.syncing = false
            this.isFirstSync = true

            this.remoteKeys = {}
            this.remoteKeysTimestamps = {}

            this.overHeadChecked = 0
            this.syncRun = 0
            this.interval = 30000

            this.parent.timer.addInterval( 'shares-sync-solo', this.interval, () =>
            {

                this.parent
                    .baseClassHelper
                    .get( 'colleague' )
                    .cacheHeatup()
                    .then( () =>
                    {

                        this.fullSync()

                    } )

            }, true )

            ShareSync.instance = this


        }

        return ShareSync.instance

    }

    /**
     * _getColleague
     * @param uuid
     * @returns {*}
     * @private
     */
    _getColleague( uuid )
    {

        let colleagues = this.parent
                             .baseClassHelper
                             .get( 'colleague' )
                             .getCache( 'cache' )

        /*eslint-disable-next-line*/
        for( const [ c, colleague ] of colleagues )
        {
            if( colleague.uuid === uuid )
            {
                return colleague
            }
        }

        return undefined

    }

    /*eslint-disable*/
    /**
     * _prepareDatabaseChanges
     * @param dbObject
     * @param share
     * @returns {{add: *[], delete: *[]}}
     * @private
     */
    _prepareDatabaseChanges( dbObject, share )
    {

        let changes = {
            delete: [],
            add   : []
        }

        for( let k in dbObject.object.keys )
        {
            if( this.parent.store.getters.uuid !== dbObject.object.keys[ k ].uuid
                && -1 === share.sharedWith.indexOf( dbObject.object.keys[ k ].uuid ) )
            {
                changes.delete.push( dbObject.object.keys[ k ].uuid )
            }
        }

        for( let s in share.sharedWith )
        {
            if( !dbObject.object.keys.find( o => o.uuid === share.sharedWith[ s ] ) )
            {
                changes.add.push( share.sharedWith[ s ] )
            }
        }

        return changes

    }

    /**
     * _prepareDatabaseRemoveKeys
     * @param dbObject
     * @param unshare
     * @returns {{add: *[], delete: *[]}}
     * @private
     */
    _prepareDatabaseRemoveKeys( dbObject, unshare )
    {

        let changes = {
            delete: [],
            add   : []
        }

        for( let s in unshare.sharedWith )
        {
            if( dbObject.object.keys.find( o => o.uuid === unshare.sharedWith[ s ] ) )
            {
                changes.delete.push( unshare.sharedWith[ s ] )
            }
        }

        return changes

    }

    /**
     * _checkSharedDatabaseObjects
     * @param shares
     * @returns {Promise<unknown>}
     * @private
     */
    _checkSharedDatabaseObjects( shares )
    {
        return new Promise( resolve =>
        {

            let promises = [],
                result   = {}

            /*eslint-disable-next-line*/
            for( const [ localId, share ] of shares )
            {

                promises.push( () =>
                {
                    return new Promise( resolve =>
                    {

                        this.parent
                            .database
                            .readObject( share.idReference )
                            .then( dbObject =>
                            {

                                let change = this._prepareDatabaseChanges( dbObject, share )
                                if( 0 < change.add.length
                                    || 0 < change.delete.length )
                                {
                                    result[ share.idReference ] = change
                                }
                                return resolve()

                            } )
                            .catch( () =>
                            {
                                return resolve()
                            } )

                    } )
                } )

            }

            this.parent
                .f
                .promiseRunner( promises )
                .then( () =>
                {

                    return resolve( result )

                } )

        } )
    }

    /**
     * _checkUnsharedDatabaseObjects
     * @param unshares
     * @returns {Promise<unknown>}
     * @private
     */
    _checkUnsharedDatabaseObjects( unshares )
    {
        return new Promise( resolve =>
        {

            let promises = [],
                result   = {}

            /*eslint-disable-next-line*/
            for( const [ localId, unshare ] of unshares )
            {

                promises.push( () =>
                {
                    return new Promise( resolve =>
                    {

                        this.parent
                            .database
                            .readObject( unshare.idReference )
                            .then( dbObject =>
                            {

                                let change = this._prepareDatabaseRemoveKeys( dbObject, unshare )
                                if( 0 < change.add.length
                                    || 0 < change.delete.length )
                                {
                                    result[ unshare.idReference ] = change
                                }
                                return resolve()

                            } )
                            .catch( () =>
                            {
                                return resolve()
                            } )

                    } )
                } )

            }

            this.parent
                .f
                .promiseRunner( promises )
                .then( () =>
                {

                    return resolve( result )

                } )

        } )
    }

    /**
     * _remoteDropKeys
     * @param uuid
     * @param remoteId
     * @private
     */
    _remoteDropKeys( uuid, remoteId )
    {

        if( uuid !== this.parent.store.getters.uuid )
        {

            if( 0 !== remoteId && undefined !== remoteId && null !== remoteId )
            {

                let colleague = this._getColleague( uuid )

                if( undefined !== colleague )
                {

                    let idColleague = colleague.colleagueId,
                        message     = {
                            method     : 'objects.removeElementKeysForColleague',
                            idColleague: idColleague,
                            idObject   : remoteId
                        },
                        jobId       = this.parent.uuid.generate()

                    this.parent.queueWorker.enqueue( 'message', jobId, 'socketMessage', JSON.stringify( message ) )

                }

            }

        }

    }

    /**
     * _fixShares
     * @param shareFixes
     * @private
     */
    _fixShares( shareFixes )
    {

        for( let s in shareFixes )
        {

            let reference = this.parent
                                .baseClassHelper
                                .get( 'share' )
                                .registry
                                .byReference
                                .get( s )

            if( undefined !== reference )
            {

                let share = this.parent
                                .baseClassHelper
                                .get( 'share' )
                                .getById( reference )

                for( let d in shareFixes[ s ].delete )
                {
                    this.parent.f.removeFromArray( share.sharedWith, shareFixes[ s ].delete[ d ] )
                }

                if( 0 === share.sharedWith.length )
                {
                    this.parent
                        .baseClassHelper
                        .get( 'share' )
                        .delete( share.localId, share.remoteId )
                }
                else
                {
                    this.parent
                        .baseClassHelper
                        .get( 'share' )
                        .update( share, share.localId, share.remoteId, share.timestamp, share.localKey )
                }

            }

        }

    }

    /**
     * _processDatabaseUpdates
     * @param updates
     * @returns {Promise<unknown>}
     * @private
     */
    _processDatabaseUpdates( updates )
    {
        return new Promise( resolve =>
        {

            let promises   = [],
                uploads    = [],
                shareFixes = {},
                counter    = 0

            for( let u in updates )
            {
                promises.push( () =>
                {

                    return new Promise( resolve =>
                    {

                        this.parent
                            .database
                            .readObject( u )
                            .then( dbObject =>
                            {

                                this.parent
                                    .database
                                    .readType( u )
                                    .then( type =>
                                    {

                                        this.parent.eventManager.dispatch( 'block-sync-for-update', u )

                                        let newObject = this.parent.f.deref( dbObject.object )
                                        newObject.keys = []
                                        for( let k in dbObject.object.keys )
                                        {
                                            if( -1 === updates[ u ].delete.indexOf( dbObject.object.keys[ k ].uuid ) )
                                            {
                                                newObject.keys.push( dbObject.object.keys[ k ] )
                                            }
                                            else
                                            {
                                                this._remoteDropKeys( dbObject.object.keys[ k ].uuid, dbObject.object.remoteId )
                                            }
                                        }

                                        if( 0 < updates[ u ].add.length )
                                        {
                                            let localKey = this.parent.cryptoHelper.getOwnElementKey( dbObject.object )

                                            for( let a in updates[ u ].add )
                                            {
                                                let colleague = this._getColleague( updates[ u ].add[ a ] )
                                                if( undefined === colleague )
                                                {
                                                    shareFixes[ u ] = shareFixes[ u ] || { delete: [], add: [] }
                                                    shareFixes[ u ].delete.push( updates[ u ].add[ a ] )
                                                }
                                                else
                                                {

                                                    let newKey = this.parent
                                                                     .cryptoHelper
                                                                     .rekey( localKey, colleague.publicKey )

                                                    if( false !== newKey && null !== newKey && undefined !== newKey )
                                                    {
                                                        newObject.keys.push( {
                                                            uuid: updates[ u ].add[ a ],
                                                            key : newKey
                                                        } )
                                                    }

                                                }
                                            }
                                        }

                                        counter++

                                        uploads.push( {
                                            type   : type,
                                            localId: u
                                        } )

                                        this.parent
                                            .database
                                            .writeObject( newObject, u, type )
                                            .then( () =>
                                            {

                                                this.parent
                                                    .baseClassHelper
                                                    .get( type )
                                                    .refreshCache( u )

                                                return resolve()
                                            } )
                                            .catch( e =>
                                            {
                                                this.parent
                                                    .logger
                                                    .cerror( this.logSign + '_processDatabaseUpdates', 'failed', e )
                                                return resolve()
                                            } )

                                    } )

                            } )

                    } )

                } )
            }

            this.parent
                .f
                .promiseRunner( promises )
                .then( () =>
                {

                    this.parent
                        .logger
                        .clog( this.logSign + '_processDatabaseUpdates', 'processed', counter, 'database updates...' )

                    if( 0 < Object.keys( shareFixes ).length )
                    {
                        this._fixShares( shareFixes )
                    }

                    return resolve( uploads )

                } )

        } )
    }

    /**
     * _processShares
     * @param shares
     * @returns {Promise<unknown>}
     * @private
     */
    _processShares( shares )
    {
        return new Promise( resolve =>
        {

            this._checkSharedDatabaseObjects( shares )
                .then( databaseUpdates =>
                {

                    this._processDatabaseUpdates( databaseUpdates )
                        .then( uploads =>
                        {

                            let counter = uploads.length
                            this.parent.database.writeUploadsList( uploads )
                                .then( () =>
                                {

/*                                    if( counter > 0 )
                                    {
                                        this.parent.ui.setPageMessage( 'Teilung erfolgreich!', 'Die zu teilenden Elemente wurden erfolgreich bearbeitet und stehen dem Kollegium in Kürze zur Verfügung!', 'ok', true )
                                    }*/

                                    this.parent
                                        .logger
                                        .clog( this.logSign + '_processShares', 'processed', counter, 'objects to be uploaded...' )

                                    return resolve()

                                } )

                        } )

                } )

        } )
    }

    /**
     * _processUnshares
     * @param unshares
     * @returns {Promise<unknown>}
     * @private
     */
    _processUnshares( unshares )
    {
        return new Promise( resolve =>
        {

            this._checkUnsharedDatabaseObjects( unshares )
                .then( databaseUpdates =>
                {

                    this._processDatabaseUpdates( databaseUpdates )
                        .then( uploads =>
                        {

                            let counter = uploads.length
                            this.parent.database.writeUploadsList( uploads )
                                .then( () =>
                                {

                                    /*eslint-disable-next-line*/
                                    this.parent
                                        .logger
                                        .clog( this.logSign + '_processShares', 'processed', counter, 'objects to be uploaded...' )

                                    return resolve()

                                } )

                        } )

                } )

        } )

    }

    /**
     * syncShares
     * @returns {Promise<unknown>}
     */
    syncShares()
    {
        return new Promise( resolve =>
        {

            this.parent
                .baseClassHelper
                .get( 'share' )
                .getPreparedCache( 'cache' )
                .then( shares =>
                {

                    this.parent
                        .logger
                        .clog( this.logSign + 'syncShares', 'processing', shares.size, 'shares...' )

                    this._processShares( shares )
                        .then( () =>
                        {

                            return resolve()

                        } )

                } )

        } )
    }

    /**
     * syncUnshares
     * @returns {Promise<unknown>}
     */
    syncUnshares()
    {
        return new Promise( resolve =>
        {

            this.parent
                .baseClassHelper
                .get( 'unshare' )
                .getPreparedCache( 'cache' )
                .then( unshares =>
                {

                    this.parent
                        .logger
                        .clog( this.logSign + 'syncUnshares', 'processing', unshares.size, 'unshares...' )

                    this._processUnshares( unshares )
                        .then( () =>
                        {

                            return resolve()

                        } )

                } )

        } )
    }

    /**
     * _checkForeignShare
     * @param element
     * @private
     */
    _checkForeignShare( element )
    {
        if( undefined !== this.remoteKeys[ element.remoteId ] )
        {
            if( undefined === this.remoteKeys[ element.remoteId ][ this.parent.store.getters.idUser ] )
            {
                this.parent
                    .baseClassHelper
                    .get( element.type )
                    .delete( element.localId, element.remoteId )
            }
        }
    }

    /**
     * _checkForeignDeletions
     * @returns {Promise<unknown>}
     * @private
     */
    _checkForeignDeletions()
    {
        return new Promise( resolve =>
        {

            this._checkRemoteKeys()
                .then( () =>
                {

                    if( this.remoteKeysFetched === true
                        && 0 < Object.keys( this.remoteKeys ).length )
                    {

                        this.parent
                            .baseClassHelper
                            .getAllObjects()
                            .then( list =>
                            {

                                for( let l in list )
                                {
                                    if( Array.isArray( list[ l ].lists ) )
                                    {
                                        for( let ll in list[ l ].lists )
                                        {
                                            if( !this.parent.rights.isOwner( list[ l ].lists[ ll ] ) )
                                            {
                                                this._checkForeignShare( list[ l ].lists[ ll ] )
                                            }
                                        }
                                    }
                                    else
                                    {
                                        if( !this.parent.rights.isOwner( list[ l ] ) )
                                        {
                                            this._checkForeignShare( list[ l ] )
                                        }
                                    }

                                }

                                return resolve()

                            } )

                    }
                    else
                    {
                        this.parent.logger.clog( this.logSign + '_checkForeignDeletions', 'no remote keys found - skipping...' )
                        return resolve()
                    }

                } )

        } )
    }

    /**
     * _checkRemoteKeys
     * @returns {Promise<unknown>}
     * @private
     */
    _checkRemoteKeys()
    {

        return new Promise( resolve =>
        {

            let remoteList = []

            this.parent
                .baseClassHelper
                .getAllObjects()
                .then( list =>
                {

                    for( let l in list )
                    {
                        if( list[ l ].type !== 'share'
                            && list[ l ].type !== 'unshare' )
                        {

                            if( list[ l ].type === 'list' && Array.isArray( list[ l ].lists ) )
                            {
                                for( let ll in list[ l ].lists )
                                {
                                    if( undefined !== list[ l ].lists[ ll ].remoteId
                                        && null !== list[ l ].lists[ ll ].remoteId
                                        && '' !== list[ l ].lists[ ll ].remoteId )
                                    {
                                        if( undefined !== list[ l ].lists[ ll ].remoteId
                                            && -1 === remoteList.indexOf( list[ l ].lists[ ll ].remoteId ) )
                                        {
                                            remoteList.push( list[ l ].lists[ ll ].remoteId )
                                        }
                                    }
                                }
                            }
                            else
                            {
                                if( undefined !== list[ l ].remoteId
                                    && -1 === remoteList.indexOf( list[ l ].remoteId ) )
                                {
                                    remoteList.push( list[ l ].remoteId )
                                }
                            }

                        }
                    }

                    this.parent
                        .client
                        .request( {
                            method: 'objects.getRemoteKeyList',
                            list  : remoteList
                        } )
                        .then( response =>
                        {

                            this.remoteKeys = null
                            this.remoteKeysTimestamps = null
                            this.remoteKeys = {}
                            this.remoteKeysTimestamps = {}

                            for( let l in response.list )
                            {
                                this.remoteKeys[ response.list[ l ].id_object ] = this.remoteKeys[ response.list[ l ].id_object ] || {}
                                this.remoteKeys[ response.list[ l ].id_object ][ response.list[ l ].id_user ] = response.list[ l ].secret
                                this.remoteKeysTimestamps[ response.list[ l ].id_object ] = this.remoteKeysTimestamps[ response.list[ l ].id_object ] || {}
                                this.remoteKeysTimestamps[ response.list[ l ].id_object ][ response.list[ l ].id_user ] = response.list[ l ].datetime_created
                            }

                            this.remoteKeysFetched = true
                            return resolve()

                        } )
                        .catch( () =>
                        {

                            this.remoteKeysFetched = false
                            return resolve()

                        } )

                } )

        } )

    }

    /**
     * _dropOldUnshares
     * @returns {Promise<unknown>}
     * @private
     */
    _dropOldUnshares()
    {

        return new Promise( resolve =>
        {

            this.parent
                .baseClassHelper
                .get( 'unshare' )
                .getPreparedCache( 'cache' )
                .then( unshares =>
                {

                    let done = 0
                    /*eslint-disable-next-line*/
                    for( const [ localId, unshare ] of unshares )
                    {

                        if( unshare.timestamp < ( Date.now() - 120000 ) )
                        {

                            done++
                            this.parent
                                .baseClassHelper
                                .get( 'unshare' )
                                .delete( unshare.localId, unshare.remoteId )

                        }
                    }

                    if( done > 0 )
                    {
                        this.parent.ui.setPageMessage( 'Teilung beendet!', 'Die nicht mehr zu teilenden Elemente wurden aus der Teilung entfernt und stehen dem Kollegium in Kürze nicht mehr zur Verfügung!', 'ok', true )
                    }

                    return resolve()

                } )

        } )

    }

    fullSync()
    {
        return new Promise( resolve =>
        {

            if( !this.syncing )
            {

                this.parent
                    .syncWorker
                    .pause( true )

                this.syncing = true
                this.parent.logger.clog( this.logSign + 'fullSync', 'synchronizing all shares states...' )

                this._dropOldUnshares()
                    .then( () =>
                    {

                        this.syncUnshares()
                            .then( () =>
                            {

                                this.syncShares()
                                    .then( () =>
                                    {

                                        this._checkForeignDeletions()
                                            .then( () =>
                                            {

                                                this.sharedMediaSync
                                                    .checkSharedMedia()
                                                    .then( () => {

                                                        this.syncing = false

                                                        this.parent
                                                            .syncWorker
                                                            .pause( false )

                                                        return resolve()

                                                    })

                                            } )

                                    } )

                            } )
                    } )

            }
            else
            {
                this.parent.logger.clog( this.logSign + 'fullSync', 'not synchronizing shares states: already working...' )
            }

        } )
    }

}