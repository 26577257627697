<template>
    <div class="studentaccess">
        Zugangsdaten-Tab
    </div>
</template>

<script>
export default {
    name  : 'StudentAccessBase',
    props : {
        item: { type: Object, required: true }
    },

    data()
    {
        return {
        }
    }
}
</script>