export default class StudentAccessesHelper
{

    constructor( core )
    {

        if( !StudentAccessesHelper.instance )
        {

            /* core classes */
            this.logger = core.getLogger()
            this.f = core.f()
            this.baseClassHelper = core.getBaseClassHelper()
            this.eventManager = core.getEventManager()
            this.queueWorker = core.getQueueWorker()

            this.uuid = core.getUuid()
            this.registerStudentAccessHelper = core.getRegisterStudentAccessHelper()

            this.setState = ( key, value ) =>
            {
                core.setState( key, value )
            }

            this.getBaseClassHelper = () =>
            {
                return this.baseClassHelper
            }

            if( undefined === this.baseClassHelper )
            {
                core.eventManager.append( 'on-baseclasses-available', () =>
                {
                    this.baseClassHelper = core.getBaseClassHelper()
                    this.baseClassHelper
                        .get( 'studentAccess' )
                        .cacheHeatup()
                } )
            }
            else
            {
                this.baseClassHelper
                    .get( 'studentAccess' )
                    .cacheHeatup()
            }

            this.setState( 'student-accesses-indexing', true )

            this.eventManager.append( 'after-cache-heatup-studentAccess', () =>
            {
                this.setState( 'student-accesses-indexing', false )
                this.eventManager.dispatchIndexed( 'on-student-accesses-refresh' )
                this.eventManager.dispatchAndRemove( 'on-student-access-ready', this )
            } )

            StudentAccessesHelper.instance = this

        }

        return StudentAccessesHelper.instance

    }

    hasStudentAccess( studentLocalId, returnValue )
    {

        let accessId = this.baseClassHelper
                           .get( 'studentAccess' )
                           .registry
                           .byReferenceId
                           .get( studentLocalId )

        return returnValue ? accessId : ( accessId !== null && accessId !== undefined )

    }

    getStudentAccess( studentLocalId )
    {

        let studentAccessLocalId = this.hasStudentAccess( studentLocalId, true )
        return this.baseClassHelper
                   .get( 'studentAccess' )
                   .getById( studentAccessLocalId )

    }

    activateStudentAccess( studentLocalId, silent )
    {

        if( !this.hasStudentAccess( studentLocalId ) )
        {

            silent = silent || false

            this.registerStudentAccessHelper
                .registerStudentAccess(
                    this.baseClassHelper
                        .get( 'student' )
                        .getById( studentLocalId ),
                    0, silent
                )

        }

    }

    deactivateStudentAccess( studentLocalId )
    {
        if( this.hasStudentAccess( studentLocalId ) )
        {

            let access = this.getStudentAccess( studentLocalId )
            if( null !== access && undefined !== access )
            {

                let message = {
                        method        : 'users.deleteStudentAccess',
                        studentLocalId: studentLocalId
                    },
                    jobId   = this.uuid
                                  .generate()

                this.queueWorker
                    .enqueue( 'message', jobId, 'socketMessage', JSON.stringify( message ) )

                this.baseClassHelper
                    .get( 'studentAccess' )
                    .delete( access.localId, access.remoteId )

            }

        }
    }

    toggleActivation( studentLocalId )
    {

        if( this.hasStudentAccess( studentLocalId ) )
        {
            this.deactivateStudentAccess( studentLocalId )
        }
        else
        {
            this.activateStudentAccess( studentLocalId )
        }

    }

}