import AbstractObjectClass from "@/objects/abstract/ObjectClass";

export default class StudentAccessObjectClass extends AbstractObjectClass
{

    constructor( core )
    {

        if( !StudentAccessObjectClass.instance )
        {

            let refList = [ 'idUser',
                            'idDevice',
                            'deviceUuid',
                            'timestamp',
                            'update',
                            'studentLocalId',
                            'username',
                            'passphrase',
                            'accessDocument',
                            'keys' ]

            let personalAttributesStatesList = []

            super( core, 'studentAccess', refList, personalAttributesStatesList )

            this.hasDetailView = true
            this.baseClassHelper = core.getBaseClassHelper()
            this.t = ( key, params ) =>
            {
                return core.t( key, params )
            }

            this.objectType = 'studentAccess'

            StudentAccessObjectClass.instance = this

        }

        return StudentAccessObjectClass.instance

    }

    getTabs()
    {
        return [ 'own', 'foreign', 'archive' ]
    }

    getFunctions()
    {
        return [ 'activate', 'edit', 'delete' ]
    }

}