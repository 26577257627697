<template>
    <div class="studentaccess">
        Elemente-Tab

        <pre>
            {{ item }}
        </pre>
    </div>
</template>

<script>
export default {
    name  : 'StudentAccessElements',
    props : {
        item: { type: Object, required: true }
    },

    data()
    {
        return {
        }
    }
}
</script>