<template>
    <div :class="'student hoverable '+trans+' ' +color+( selected ? ' selected-item' : '' )"
         :key="'student-'+item.localId+'-'+item.elementKey+'-'+selected" @click="$emit( 'clicked' )">
        <div
            :class="'sensitive studentName'+( $core.settings().getSetting( 'supportMode' ) === true ? ' blurred' : '' )">
            <strong>{{ item.lastname }}</strong>, {{ item.firstname }}
        </div>
        <div v-if="accessPreview" class="hidden" :id="'access_qr_'+accessPreview.studentLocalId">
            <pre>{{ accessPreview.studentLocalId }}</pre>
            <StudentAccessQr :localAccess="accessPreview"/>
        </div>
        <div class="student-markerspace">
            <div class="inner">
                <StudentAccessQuickInfo
                    :accessId
                    :access/>
            </div>
        </div>
        <div :class="'classname' + classLongClassnames"
             :style="widthLongClassnames"
             v-if="$core.f().isset( item.classname ) && 'Z_FALSE' !== item.classname">
            {{ item.classname }}
        </div>
        <div v-else :class="'classname' + classLongClassnames"
             style="background: transparent!important;">
        </div>
    </div>
</template>
<script>

import MixinWatchElementKey   from "@/mixins/MixinWatchElementKey";
import StudentAccessQuickInfo from "@/components/elements/viewItems/studentAccess/StudentAccessQuickInfo.vue";
import MixinEvents            from "@/mixins/MixinEvents.vue";
import StudentAccessQr        from "@/components/routes/secretary/studentAccess/StudentAccessQr.vue";

export default {
    name      : 'StudentAccess',
    components: { StudentAccessQr, StudentAccessQuickInfo },

    props: {
        item      : { type: Object, required: true },
        selected  : { type: Boolean, required: false, default: false },
        elementKey: { type: String, required: false, default: '' }
    },

    emits : [ 'clicked' ],
    mixins: [ MixinWatchElementKey, MixinEvents ],

    data()
    {
        return {
            colorModel          : this.$core.settings().getSetting( 'studentColorModel' ),
            color               : '',
            trans               : '',
            classLongClassnames : '',
            widthLongClassnames : '',
            marginLongClassnames: '',
            accessId            : false,
            access              : false,
            accessPreview       : false
        }
    },

    created()
    {
        this.prepare()
        this.addIndexedEvent( 'on-student-accesses-refresh', () =>
        {
            this.checkAccess()
        } )
        this.addIndexedEvent( 'on-preview-access-' + this.$props.item.localId, ( studentAccess ) =>
        {
            this.accessPreview = studentAccess
        } )
    },

    mounted()
    {
        this.checkAccess()
    },

    methods: {

        checkAccess()
        {
            this.accessId = this.$core.studentAccesses().hasStudentAccess( this.$props.item.localId, true )
            if( this.accessId )
            {
                this.access = this.$core.studentAccesses().getStudentAccess( this.$props.item.localId )
                this.trans = ''
            }
            else
            {
                this.trans = 'inactive'
            }
        },

        prepare()
        {
            this.color = 'colors' === this.colorModel ?
                         this.$props.item.color : this.$core.f().genderColorClass( this.$props.item.gender )
            this.classLongClassnames = -1 < this.$core.settings().getSetting( 'maxClassNameLength' ) ? ' long' : ''
            this.widthLongClassnames = -1 < this.$core.settings().getSetting( 'maxClassNameLength' ) ?
                                       'width: ' + Math.ceil( ( this.$core.settings().getSetting( 'maxClassNameLength' ) / 4 ) * 100 ) + 'px' : ''
            this.marginLongClassnames = -1 < this.$core.settings().getSetting( 'maxClassNameLength' ) ?
                                        'margin-left: ' + Math.ceil( ( this.$core.settings().getSetting( 'maxClassNameLength' ) / 4 ) * 100 ) + 'px' : ''
        }
    }

}
</script>