import { createWebHashHistory, createRouter } from 'vue-router'

const Login = () => import('@/components/routes/login/Login')
const Dashboard = () => import('@/components/routes/dashboard/Dashboard')
const Students = () => import('@/components/routes/students/Students')
const StudentsAccesses = () => import('@/components/routes/students-accesses/StudentsAccesses')
const Classes = () => import('@/components/routes/classes/Classes')
const Groups = () => import('@/components/routes/groups/Groups')
const YearGroups = () => import('@/components/routes/yeargroups/YearGroups')
const Notes = () => import('@/components/routes/notes/Notes')
const Office = () => import('@/components/routes/office/Office')
const Settings = () => import('@/components/routes/settings/Settings')
const Todos = () => import('@/components/routes/todos/Todos')
const Planner = () => import('@/components/routes/planner/Planner')
const Colleagues = () => import('@/components/routes/colleagues/Colleagues')
const Messages = () => import('@/components/routes/messages/Messages' )
const Secretary = () => import('@/components/routes/secretary/Secretary' )
const Documents = () => import('@/components/routes/documents/Documents' )
const BackupKey = () => import('@/components/routes/login/BackupKey' )
const BackupKeyCreated = () => import('@/components/routes/login/BackupKeyCreated')
const PasswordReset = () => import('@/components/routes/passwordReset/PasswordReset' )
const Qr = () => import( '@/components/routes/qr/Qr' )
const Feedback = () => import('@/components/routes/feedback/Feedback')
const Promotion = () => import('@/components/routes/promotion/Promotion' )
const Shortcuts = () => import('@/components/routes/shortcuts/Shortcuts' )
const Competences = () => import('@/components/routes/competences/Competences.vue' )

/* eslint-disable */

const routes = [
    {
        path     : '/',
        name     : 'default',
        component: Login
    },
    {
        path     : '/login',
        name     : 'login',
        component: Login
    },
    {
        path     : '/demo',
        name     : 'demo',
        component: Login
    },
    {
        path     : '/passwordReset',
        name     : 'passwordReset',
        component: PasswordReset
    },
    {
        path     : '/register',
        name     : 'register',
        component: Login
    },
    {
        path     : '/dashboard',
        name     : 'dashboard',
        component: Dashboard
    },
    {
        path     : '/students',
        name     : 'students',
        component: Students
    },
    {
        path     : '/studentsaccesses',
        name     : 'studentsaccesses',
        component: StudentsAccesses
    },
    {
        path     : '/classes',
        name     : 'classes',
        component: Classes
    },
    {
        path     : '/colleagues',
        name     : 'colleagues',
        component: Colleagues
    },
    {
        path     : '/groups',
        name     : 'groups',
        component: Groups
    },
    {
        path     : '/yeargroups',
        name     : 'yeargroups',
        component: YearGroups
    },
    {
        path     : '/notes',
        name     : 'notes',
        component: Notes
    },
    {
        path     : '/office',
        name     : 'office',
        component: Office
    },
    {
        path     : '/documents',
        name     : 'documents',
        component: Documents
    },
    {
        path     : '/competences',
        name     : 'competences',
        component: Competences
    },
    {
        path     : '/planner',
        name     : 'planner',
        component: Planner
    },
    {
        path     : '/planner/:mode/:tsmp',
        name     : 'plannerRedirect',
        component: Planner
    },
    {
        path     : '/settings',
        name     : 'settings',
        component: Settings
    },
    {
        path     : '/todos',
        name     : 'todos',
        component: Todos
    },
    {
        path     : '/messages',
        name     : 'messages',
        component: Messages
    },
    {
        path     : '/admin',
        name     : 'admin',
        component: Secretary
    },
    {
        path     : '/puk/:backupKey',
        name     : 'backupKey',
        component: BackupKey
    },
    {
        path     : '/paypal/:token',
        name     : 'paypalReturn',
        component: Settings
    },
    {
        path     : '/pukCreated/:backupKey',
        name     : 'backupKeyCreated',
        component: BackupKeyCreated
    },
    {
        path     : '/settings/:tab',
        name     : 'settingsWithTab',
        component: Settings
    },
    {
        path     : '/qr/:type/:code',
        name     : 'qr',
        component: Qr
    },
    {
        path     : '/office/:tab/:action/:code',
        name     : 'office-tab-action',
        component: Office
    },
    {
        path     : '/feedback/:scope',
        name     : 'feedback',
        component: Feedback
    },
    {
        path     : '/promotion',
        name     : 'promotion',
        component: Promotion
    },
    {
        path     : '/shortcuts/:type/:key',
        name     : 'shortcuts',
        component: Shortcuts
    }
]

const router = createRouter( {
    routes,
    history: createWebHashHistory(),
    scrollBehavior( to, from, savedPosition )
    {
        if( to.hash )
        {
            return {
                selector: to.hash,
                behavior: 'smooth'
            }
        }
    }
} )

router.beforeEach( ( to, from, next ) =>
{

    let routesAllowed = [
        'demo',
        'register',
        'login',
        'backupKey',
        'default',
        'passwordReset',
        'shortcuts'
    ]

    let element = document.querySelector( '#header' )
    if( null !== element )
    {
        element.scrollIntoView( { behavior: 'smooth' } )
    }

    if( -1 < routesAllowed.indexOf( to.name ) )
    {
        next()
    }
    else
    {
        if( undefined === window.authKey )
        {
            setTimeout( () =>
            {
                if( null !== window.authKey
                    && undefined !== window.authKey )
                {
                    next()
                }
                else
                {
                    next( { name: 'default' } )
                }
            }, 200 )
        }
        else
        {
            if( null !== window.authKey )
            {
                next()
            }
            else
            {
                next( { name: 'default' } )
            }
        }

    }

} )

export default router