export default class FullTranslations
{

    constructor()
    {
        return this
    }

    get()
    {
        return {

            'page-experimental'                             : 'Experimente',
            'page-students'                                 : 'Schüler:innen',
            'page-studentsaccesses'                         : 'Zugänge für Schüler:innen',
            'page-classes'                                  : 'Klassen',
            'page-groups'                                   : 'Gruppen',
            'page-yeargroups'                               : 'Jahrgänge',
            'page-office'                                   : 'Büro',
            'page-documents'                                : 'Dokumente',
            'page-competences'                              : 'Kompetenzen',
            'page-notes'                                    : 'Notizen',
            'page-todos'                                    : 'Todos',
            'page-izel'                                     : 'IZeL',
            'page-planner'                                  : 'Kalender',
            'page-colleagues'                               : 'Kollegium',
            'page-secretary'                                : 'Admin',
            'page-messages'                                 : 'Postfach',
            'inbox'                                         : 'Posteingang',
            'outbox'                                        : 'Postausgang',
            'opened'                                        : 'geöffnet',
            'short-key-forward'                             : 'WG:',
            'short-key-answer'                              : 'RE:',
            'message-direction-key-forward'                 : 'weitergeleitete Nachricht',
            'message-direction-key-answer'                  : 'Nachricht',
            'page-settings'                                 : 'Einstellungen',
            'blocker-resorting'                             : '<strong>Sortiere Elemente</strong><br/>%%param0%%',
            'entzettelt_html'                               : '<strong>entzettelt</strong>',
            'caption_firstname'                             : 'Vorname',
            'caption_lastname'                              : 'Nachname',
            'caption_gender'                                : 'Geschlecht',
            'caption_confession'                            : 'Konfession',
            'caption_birthdate'                             : 'Geburtsdatum',
            'caption_address'                               : 'Anschrift',
            'caption_address2'                              : 'Anschrift (Zusatz)',
            'caption_zipcode'                               : 'PLZ',
            'caption_city'                                  : 'Ort',
            'caption_classname'                             : 'Klassenname',
            'caption_zipcode_city'                          : 'PLZ, Ort',
            'caption_parents'                               : 'Eltern',
            'caption_parent1'                               : 'Name Elternteil 1',
            'caption_parent2'                               : 'Name Elternteil 2',
            'caption_phone_parent1'                         : 'Telefonnummer Elternteil 1',
            'caption_phone_parent2'                         : 'Telefonnummer Elternteil 2',
            'caption_age'                                   : 'Alter',
            'caption_phone'                                 : 'Telefonische Erreichbarkeit',
            'caption_phone1'                                : 'erste Telefonnummer',
            'caption_phone2'                                : 'zweite Telefonnummer',
            'element-type-date-filter'                      : 'Termine filtern',
            'element-type-todo-filter'                      : 'Todos filtern',
            'element-type-list-filter'                      : 'Büro-Elemente filtern',
            'element-type-note-filter'                      : 'Notizen filtern',
            'form-title-multiedit-student'                  : '%%param0%% Schüler:innen bearbeiten',
            'form-title-multiedit-note'                     : '%%param0%% Notizen bearbeiten',
            'wording-average'                               : 'Durchschnitt',
            'female'                                        : 'weiblich',
            'male'                                          : 'männlich',
            'divers'                                        : 'divers',
            'mirror_name_male'                              : 'Heinz',
            'mirror_name_female'                            : 'Anna',
            'mirror_name_divers'                            : 'Didi',
            'mirror_address_1'                              : 'Entzetteltweg 1',
            'mirror_address_2'                              : '',
            'mirror_city'                                   : 'Berlin',
            'mirror_zipcode'                                : '12345',
            'mirror_parent_1'                               : 'Hans Pansen',
            'mirror_parent_2'                               : 'Judith Pansen',
            'bacon_ipsum'                                   : 'Bacon ipsum dolor amet chislic capicola pork, spare ribs doner leberkas tri-tip. Chuck short loin cupim pork drumstick strip steak. Cow tri-tip meatloaf, bresaola tongue jowl hamburger alcatra capicola turkey drumstick. Pork sausage chicken tenderloin t-bone frankfurter. Cupim picanha buffalo spare ribs, bresaola kevin pancetta shoulder. Swine pastrami kielbasa tongue shoulder tail landjaeger burgdoggen chicken rump corned beef pork chop.',
            'lorem_ipsum_1'                                 : 'Lorem ipsum dolor sit amet, consectetur adipiscing elit. Pellentesque facilisis justo et orci pharetra egestas. Donec in ultrices lacus. Nullam quis imperdiet sem. Duis turpis turpis, pellentesque id urna nec, consectetur volutpat lorem.',
            'lorem_ipsum_2'                                 : 'Ut nec nisl at nisi dictum dictum. Nunc maximus finibus eros quis imperdiet. Mauris id dictum diam. Integer viverra diam eu massa aliquet pharetra. Suspendisse porta porttitor dui non ultrices. Vestibulum tincidunt velit vel luctus interdum.',
            'lorem_ipsum_3'                                 : 'Fusce cursus nec dolor rutrum placerat. Praesent vestibulum felis dui, nec fringilla urna fringilla nec. Mauris vehicula nunc sit amet neque venenatis gravida. Praesent volutpat, dui nec dictum mattis, neque orci varius nisl, tempus finibus lectus eros ac erat.',
            'lorem_ipsum_4'                                 : 'Aenean nec vehicula risus. Vestibulum vestibulum augue non ultricies pharetra. Donec consectetur congue nulla, id tempor purus faucibus convallis. Suspendisse potenti. Nullam leo lectus, scelerisque nec arcu eget, posuere gravida tortor. Cras dignissim diam libero.',
            'lorem_ipsum_5'                                 : 'Maecenas id commodo mi. Praesent non metus quis justo venenatis facilisis. Mauris non neque tortor. Etiam bibendum erat venenatis viverra malesuada. Nunc ipsum risus, ornare ac magna eu, ultrices blandit est. Etiam quam felis, placerat id lorem at, condimentum commodo nisl.',
            'lorem_ipsum_6'                                 : '',
            'lorem_ipsum_7'                                 : '',
            'short_ipsum_1'                                 : 'Lorem ipsum dolor sit amet, consectetur adipiscing elit.',
            'short_ipsum_2'                                 : 'Ut nec nisl at nisi dictum dictum.',
            'short_ipsum_3'                                 : 'Fusce cursus nec dolor rutrum placerat. Praesent vestibulum felis dui.',
            'short_ipsum_4'                                 : 'Aenean nec vehicula risus.',
            'short_ipsum_5'                                 : 'Praesent non metus quis justo venenatis facilisis.',
            'short_ipsum_6'                                 : '',
            'short_ipsum_7'                                 : '',
            'mirror_subject'                                : 'Spare Ribs Döner',
            'threeway-no'                                   : 'NEIN',
            'threeway-yes'                                  : 'JA',
            'threeway-undefined'                            : 'KEINE ANGABE',
            'own'                                           : 'meine',
            'foreign'                                       : 'geteilte',
            'generic-all-students'                          : 'alle Schüler:innen',
            'generic-students'                              : 'Schüler:innen',
            'generic-colleagues'                            : 'Kolleg:innen',
            'are-being-initialized'                         : 'werden geladen...',
            'templates'                                     : 'Vorlagen',
            'archive'                                       : 'Archiv',
            'class'                                         : 'Klasse',
            'object-type-element-single'                    : 'Element',
            'object-type-message'                           : 'Nachricht',
            'object-type-messages-single'                   : 'Nachricht',
            'object-type-messages-single.form-method-create': 'senden',
            'object-type-note'                              : 'Notiz',
            'object-type-student'                           : 'Schüler:in',
            'object-type-class'                             : 'Klasse',
            'object-type-team'                              : 'Team',
            'object-type-teams'                             : 'Teams',
            'object-type-group'                             : 'Gruppe',
            'object-type-yeargroup'                         : 'Jahrgang',
            'object-type-todo'                              : 'Todo',
            'object-type-template'                          : 'Vorlage',
            'object-type-notes-single'                      : 'Notiz',
            'object-type-teams-single'                      : 'Team',
            'object-type-templates-single'                  : 'Vorlage',
            'object-type-students-single'                   : 'Schüler:in',
            'object-type-classes-single'                    : 'Klasse',
            'object-type-groups-single'                     : 'Gruppe',
            'object-type-yeargroups-single'                 : 'Jahrgang',
            'object-type-todos-single'                      : 'Todo',
            'object-type-lists-single'                      : 'Liste',
            'object-type-dates-single'                      : 'Termin',
            'object-type-colleagues-single'                 : 'Kollegin / Kollegen',
            'object-type-notes'                             : 'Notizen',
            'object-type-media'                             : 'Dokumente',
            'object-type-medias'                            : 'Dokumente',
            'object-type-media-single'                      : 'Dokument',
            'object-type-medias-single'                     : 'Dokument',
            'object-type-students'                          : 'Schüler:innen',
            'object-type-classes'                           : 'Klassen',
            'object-type-avatar'                            : 'Profilbild',
            'object-type-avatars'                           : 'Profilbilder',
            'object-type-templates'                         : 'Vorlagen',
            'object-type-classs'                            : 'Klassen',
            'object-type-groups'                            : 'Gruppen',
            'object-type-yeargroups'                        : 'Jahrgänge',
            'object-type-todos'                             : 'Todos',
            'object-type-date'                              : 'Termin',
            'object-type-dates'                             : 'Termine',
            'object-type-lists'                             : 'Listen & Klassenarbeiten',
            'object-type-office'                            : 'Büro',
            'object-type-list'                              : 'Liste',
            'object-type-colleague'                         : 'Kolleg:in',
            'object-type-colleagues'                        : 'Kolleg:innen',
            'object-type-DefaultObject'                     : 'Element',
            'object-type-foreign'                           : 'mit mir geteilt',
            'object-type-messages'                          : 'Nachrichten',
            'object-type-scorings'                          : 'Notenübersicht',
            'object-type-secretary'                         : 'Admin',
            'object-type-competences'                       : 'Kompetenzen',
            'object-type-competence-single'                 : 'Kompetenz',
            'object-type-competences-single'                : 't:object-type-competence-single',
            'object-type-competence'                        : 't:object-type-competence-single',
            'object-type-studentaccess'                     : 'Zugänge für Schüler:innen',
            'object-type-studentAccess'                     : 'Zugänge',
            'object-type-competenceCategories'              : 'Kompetenzen',
            'object-type-competencecategorys-single'        : 'Kompetenzbereich',
            'object-type-competenceCategory'                : 'Kompetenzbereich',
            'object-type-competencecategory'                : 'Kompetenzbereich',
            'create-new-competence-category'                : 'Neuen Kompetenzbereich anlegen',
            'title-no-competence-content'                   : 'Keine Listen gefunden',
            'message-no-competence-content'                 : '<strong>Zu diesem Kompetenzbereich hast du noch keine Listen angelegt</strong>.<br/><br/>Das kannst du natürlich ändern: Lege einfach Flex-Listen an, in denen du Kompetenzfelder verwendest.<br/>So lässt sich der Fortschritt deiner Schützlinge einfach dokumentieren und sobald du solche Listen angelegt und entsprechend ausgefüllt hast.',
            'day-title-1'                                   : 'Montag',
            'day-title-2'                                   : 'Dienstag',
            'day-title-3'                                   : 'Mittwoch',
            'day-title-4'                                   : 'Donnerstag',
            'day-title-5'                                   : 'Freitag',
            'day-title-6'                                   : 'Wochenende',
            'day-num-0'                                     : 'Sonntag',
            'day-num-1'                                     : 'Montag',
            'day-num-2'                                     : 'Dienstag',
            'day-num-3'                                     : 'Mittwoch',
            'day-num-4'                                     : 'Donnerstag',
            'day-num-5'                                     : 'Freitag',
            'day-num-6'                                     : 'Samstag',
            'sortrules-notes'                               : 'Sortierung von <strong>Notizen</strong>',
            'sortrules-todos'                               : 'Sortierung von <strong>Todos</strong>',
            'sortrules-students'                            : 'Sortierung von <strong>Schüler:innen</strong>',
            'sortrules-classes'                             : 'Sortierung von <strong>Klassen</strong>',
            'sortrules-groups'                              : 'Sortierung von <strong>Gruppen</strong>',
            'sortrules-yeargroups'                          : 'Sortierung von <strong>Jahrgängen</strong>',
            'sortrules-office'                              : 'Sortierung von <strong>Listen & Klassenarbeiten</strong>',
            'sortrules-lists'                               : 'Sortierung von <strong>Listen & Klassenarbeiten</strong>',
            'share'                                         : 'teilen',
            'shared'                                        : 'geteilt mit',
            'share-with-students'                           : 'für Schüler:innen bereitstellen',
            'shared-with-students'                          : 'bereitgestellt',
            'toggle-on'                                     : 'EIN',
            'toggle-off'                                    : 'AUS',
            'message-delete-caption'                        : 'Nachricht löschen',
            'message-delete-text'                           : 'Bist Du sicher, dass Du die Nachricht<br/>"<strong>%%subject%%</strong>" in den Papierkorb verschieben möchtest?',
            'hide'                                          : 'ausblenden',
            'show'                                          : 'einblenden',
            'title-colleagues'                              : 'Kollegium',
            'title-add-colleague'                           : 'Kollegin / Kollegen einladen',
            'statistics-class'                              : 'Klassen',
            'statistics-group'                              : 'Gruppen',
            'statistics-student'                            : 'Schüler:innen',
            'statistics-list'                               : 'Listen & Klassenarbeiten',
            'statistics-note'                               : 'Notizen',
            'statistics-date'                               : 'Termine',
            'missing-rights'                                : 'Leider fehlt dir die Berechtigung, diesen Bereich zu sehen.',
            'delete'                                        : 'löschen',
            'text-modal-delete'                             : 'Bist du dir sicher, dass du',
            'delete-note'                                   : 'die Notiz',
            'delete-list'                                   : 'die Liste',
            'delete-todo'                                   : 'das Todo',
            'text-modal-delete-end'                         : 'löschen möchtest?',
            'time'                                          : ' Zeit',
            'freetext'                                      : 'Freitext',
            'caption-delete-confirm'                        : 'löschen',
            'caption-cancel'                                : 'abbrechen',
            'caption-submit'                                : 'speichern',
            'caption-preview'                               : 'Vorschau',
            'caption-send'                                  : 'Nachricht senden',
            'admin'                                         : 'Admin',
            'week'                                          : 'Woche',
            'month'                                         : 'Monat',
            'read'                                          : 'Lesen',
            'change'                                        : 'Bearbeiten',
            'readMeta'                                      : 'Stammdaten lesen',
            'changeMeta'                                    : 'Stammdaten bearbeiten',
            'clone'                                         : 'Kopie erzeugen',
            'fill'                                          : 'Ausfüllen',
            'markDone'                                      : 'Erledigen',
            'generic-please-wait'                           : 'Augenblick bitte',
            'generic-changes-processing'                    : 'Die Änderungen werden übernommen',
            'delete-processing'                             : 'Löschung wird durchgeführt',
            'archive-processing'                            : 'Archivierung wird durchgeführt',
            'unarchive-processing'                          : 'Reaktivierung wird durchgeführt',
            'generic-please-select'                         : 'bitte wählen...',
            'generic-please-select-type'                    : 'Typ wählen...',
            'please-select-competence-category'             : 'Kompetenzbereich wählen...',
            'description'                                   : 'Beschreibung',
            'generic-enter-description'                     : 'Gib hier eine Beschreibung ein',
            'is-being-update'                               : 'wird aktualisiert...',
            'is-being-create'                               : 'wird angelegt...',
            'is-being-cloned'                               : 'wird als leere Version angelegt...',
            'form-colleagues-description'                   : 'Hier kannst du Kollegen und Kolleginnen zu deinem "virtuellen Kollegium" hinzufügen.<br/>' +
                                                              'Hierfür benötigst du die PIN des Kollegen, bzw. der Kollegin. Sofern du diese PIN bereits ' +
                                                              'erhalten hast, trage sie im folgenden Feld ein und klicke anschliessend auf "Einladung ' +
                                                              'versenden".',
            'noContentsCaptionDefault'                      : 'Nichts zu finden',
            'noContentsTextDefault'                         : 'In diesem Bereich gibt es keine Elemente.<br/>Dies kann an deinen Filtereinstellungen liegen oder du hast einfach noch keine Elemente angelegt, die hier angezeigt werden könnten.',
            'modalDeleteCaption-yeargroup'                  : 'Jahrgang löschen',
            'modalDeleteText-yeargroup'                     : 'Bist du dir sicher, dass du diesen Jahrgang löschen möchtest?',
            'modalDeleteCaption-student'                    : 'Schüler:innendatensatz löschen',
            'modalDeleteText-student'                       : 'Bist Du Dir sicher, dass du den Datensatz von <strong>%%displayName%%</strong> löschen möchtest?',
            'modalDeleteText-class'                         : 'Bist Du Dir sicher, dass du die Klasse <strong>%%classname%%</strong> löschen möchtest?',
            'modalDeleteText-media'                         : 'Bist Du Dir sicher, dass du das Dokument <strong>%%displayName%%</strong> löschen möchtest?<br/><br/><strong>Beachte bitte</strong>, dass das Dokument auch aus allen Listen gelöscht wird, in denen du ggf. verwendest!',
            'modalDeleteText-group'                         : 'Bist Du Dir sicher, dass du die Gruppe <strong>%%groupname%%</strong> löschen möchtest?',
            'modalDeleteCaption-list'                       : 'Liste löschen',
            'modalDeleteText-list'                          : 'Bist Du Dir sicher, dass du <strong>%%listname%%</strong> löschen möchtest?',
            'modalDeleteText-competencecategory'            : 'Bist Du Dir sicher, dass du <strong>%%title%%</strong> löschen möchtest?',
            'modalArchiveCaption-student'                   : 'Schüler:innendatensatz archivieren',
            'modalArchiveText-student'                      : 'Bist Du Dir sicher, dass du den Datensatz von <strong>%%displayName%%</strong> archivieren möchtest?',
            'modalArchiveText-class'                        : 'Bist Du Dir sicher, dass du die Klasse <strong>%%classname%%</strong> archivieren möchtest?',
            'modalArchiveText-group'                        : 'Bist Du Dir sicher, dass du die Gruppe <strong>%%groupname%%</strong> archivieren möchtest?',
            'modalArchiveCaption-list'                      : 'Liste archivieren',
            'modalArchiveText-list'                         : 'Bist Du Dir sicher, dass du <strong>%%listname%%</strong> archivieren möchtest?',
            'modalUnarchiveCaption-student'                 : 'Schüler:innendatensatz reaktivieren',
            'modalUnarchiveText-student'                    : 'Bist Du Dir sicher, dass du den Datensatz von <strong>%%displayName%%</strong> reaktivieren möchtest?',
            'modalUnarchiveText-class'                      : 'Bist Du Dir sicher, dass du die Klasse <strong>%%classname%%</strong> reaktivieren möchtest?',
            'modalUnarchiveText-group'                      : 'Bist Du Dir sicher, dass du die Gruppe <strong>%%groupname%%</strong> reaktivieren möchtest?',
            'modalUnarchiveCaption-list'                    : 'Liste reaktivieren',
            'modalUnarchiveText-list'                       : 'Bist Du Dir sicher, dass du <strong>%%listname%%</strong> reaktivieren möchtest?',
            'modalMultiDeleteCaption-list'                  : 'Listen löschen',
            'modalMultiDeleteText-list'                     : 'Von deiner Liste "<strong>%%listname%%</strong>" gibt es mehrere Versionen. ' +
                                                              'Bitte wähle die Versionen aus, die gelöscht werden sollen und bestätige deine Auswahl durch einen Klick auf "löschen"',
            'modalMultiArchiveCaption-list'                 : 'Listen archivieren',
            'modalMultiArchiveText-list'                    : 'Von deiner Liste "<strong>%%listname%%</strong>" gibt es mehrere Versionen. ' +
                                                              'Bitte wähle die Versionen aus, die archiviert werden sollen und bestätige deine Auswahl durch einen Klick auf "archivieren"',
            'modalMultiUnarchiveCaption-list'               : 'Listen reaktivieren',
            'modalMultiUnarchiveText-list'                  : 'Von deiner Liste "<strong>%%listname%%</strong>" gibt es mehrere Versionen. ' +
                                                              'Bitte wähle die Versionen aus, die reaktiviert werden sollen und bestätige deine Auswahl durch einen Klick auf "reaktivieren"',
            'modalDeleteCaption-note'                       : 'Notiz löschen',
            'modalDeleteText-note'                          : 'Bist du dir sicher, dass du die Notiz löschen möchtest?',
            'modalDeleteCaption-date'                       : 'Termin löschen',
            'modalDeleteText-date'                          : 'Bist du dir sicher, dass du den Termin löschen möchtest?',
            'modalDeleteCaption-team'                       : 'Team löschen',
            'modalDeleteText-team'                          : 'Bist du dir sicher, dass du das Team löschen möchtest?',
            'modalDeleteCaption-todo'                       : 'Todo löschen',
            'modalDeleteText-todo'                          : 'Bist du dir sicher, dass du das Todo löschen möchtest?',
            'modalDeleteCaption-colleague'                  : 'Kolleg:in löschen',
            'modalDeleteText-colleague'                     : 'Bist du dir sicher, dass du die Kollegin / den Kollegen löschen möchtest?<br/><br/><strong>Beachte bitte</strong>, dass du damit sämtliche Elemente, die mit dir geteilt worden sind, verlierst. Auch deine geteilten Elemente werden nach diesem Vorgang nicht mehr geteilt.',
            'modalDeleteText-template'                      : 'Bist du dir sicher, dass du diese Vorlage löschen möchtest?',
            'modalDeleteText-message'                       : 'Bist du dir sicher, dass du die Nachricht <strong>%%title%%</strong> löschen möchtest??',
            'modalArchiveCaption-note'                      : 'Notiz archivieren',
            'modalArchiveText-note'                         : 'Bist du dir sicher, dass du die Notiz archivieren möchtest?',
            'modalArchiveCaption-date'                      : 'Termin archivieren',
            'modalArchiveText-date'                         : 'Bist du dir sicher, dass du den Termin archivieren möchtest?',
            'modalArchiveCaption-team'                      : 'Team archivieren',
            'modalArchiveText-team'                         : 'Bist du dir sicher, dass du das Team archivieren möchtest?',
            'modalArchiveCaption-todo'                      : 'Todo archivieren',
            'modalArchiveText-todo'                         : 'Bist du dir sicher, dass du das Todo archivieren möchtest?',
            'modalArchiveCaption-colleague'                 : 'Kolleg:in archivieren',
            'modalArchiveText-colleague'                    : 'Bist du dir sicher, dass du die Kollegin / den Kollegen archivieren möchtest?<br/><br/><strong>Beachte bitte</strong>, dass du damit sämtliche Elemente, die mit dir geteilt worden sind, verlierst. Auch deine geteilten Elemente werden nach diesem Vorgang nicht mehr geteilt.',
            'modalArchiveText-template'                     : 'Bist du dir sicher, dass du diese Vorlage archivieren möchtest?',
            'modalArchiveText-message'                      : 'Bist du dir sicher, dass du die Nachricht <strong>%%title%%</strong> archivieren möchtest??',
            'modalUnarchiveCaption-note'                    : 'Notiz reaktivieren',
            'modalUnarchiveText-note'                       : 'Bist du dir sicher, dass du die Notiz reaktivieren möchtest?',
            'modalUnarchiveCaption-date'                    : 'Termin reaktivieren',
            'modalUnarchiveText-date'                       : 'Bist du dir sicher, dass du den Termin reaktivieren möchtest?',
            'modalUnarchiveCaption-team'                    : 'Team reaktivieren',
            'modalUnarchiveText-team'                       : 'Bist du dir sicher, dass du das Team reaktivieren möchtest?',
            'modalUnarchiveCaption-todo'                    : 'Todo reaktivieren',
            'modalUnarchiveText-todo'                       : 'Bist du dir sicher, dass du das Todo reaktivieren möchtest?',
            'modalUnarchiveCaption-colleague'               : 'Kolleg:in reaktivieren',
            'modalUnarchiveText-colleague'                  : 'Bist du dir sicher, dass du die Kollegin / den Kollegen reaktivieren möchtest?<br/><br/><strong>Beachte bitte</strong>, dass du damit sämtliche Elemente, die mit dir geteilt worden sind, verlierst. Auch deine geteilten Elemente werden nach diesem Vorgang nicht mehr geteilt.',
            'modalUnarchiveText-template'                   : 'Bist du dir sicher, dass du diese Vorlage reaktivieren möchtest?',
            'modalUnarchiveText-message'                    : 'Bist du dir sicher, dass du die Nachricht <strong>%%title%%</strong> reaktivieren möchtest??',
            'confession_ohne'                               : 'Ohne Konfession',
            'confession_evangelisch'                        : 'Evangelisch',
            'confession_katholisch'                         : 'Katholisch',
            'confession_freikirchlich'                      : 'Freikirchlich',
            'confession_buddhismus'                         : 'Buddhismus',
            'confession_hinduismus'                         : 'Hinduismus',
            'confession_islam'                              : 'Islam',
            'confession_jude'                               : 'Jüdisch',
            'confession_orthodox'                           : 'Orthodox',
            'confession_zeugen jehovas'                     : 'Zeugen Jehovas',
            'title-login-form-known'                        : 'Willkommen zurück',
            'title-login-form-guest'                        : 'Willkommen',
            'form-error-generic-heading'                    : 'Ooops: Das war wohl nix.',
            'form-error-generic-message'                    : 'Das Formular wurde nicht korrekt ausgefüllt: Bitte überprüfe die <strong>rot</strong> markierten Felder und versuche es noch einmal.',
            'message-empty-own'                             : 'Das kannst du natürlich ändern: Lege einfach einen neuen Eintrag an, indem du auf das <strong>Plus</strong>-Symbol oben rechts klickst.<br/><br/><strong>Wenn du dich gerade zum ersten Mal an diesem Gerät angemeldet hast</strong> und dir sicher bist, dass hier mehr zu sehen sein sollte, warte bitte einige Sekunden, bis die Synchronisierung abgeschlossen wurde.',
            'message-empty-archive'                         : 'Vermutlich hast du noch keinen Schuljahreswechsel durchgeführt?<br/>Wenn du im Einstellungsbereich zum Ende des Schuljahres einen Wechsel durchführst, werden alle aktuellen Elemente in das Archiv verschoben und deine Klassen automatisch für das neue Schuljahr vorbereitet. Dann wirst du auch an dieser Stelle Elemente vorfinden.',
            'message-empty-foreign'                         : 'Scheinbar wurden noch keine Elemente mit dir geteilt.<br/>Das muss nicht so bleiben: Sobald eine Kollegin oder ein Kollege Elemente mit dir teilt, tauchen sie automatisch in dieser Ansicht auf.',
            'message-empty-inbox'                           : 'Sobald du Nachrichten empfangen hast, werden sie hier angezeigt.',
            'message-empty-media'                           : 'Du hast noch keine Dokumente angelegt.',
            'message-empty-outbox'                          : 'Sobald du Nachrichten innerhalb deines Kollegiums versendet hast, werden sie hier angezeigt.',
            'message-empty-lists_not_test'                  : 'Hier sind noch keine Listen zu finden.',
            'message-empty-lists_only_test'                 : 'Hier sind noch keine Klassenarbeiten oder Listen mit Benotung zu finden.',
            'message-empty-lists'                           : 'Hier sind noch keine Listen oder Klassenarbeiten zu finden.',
            'message-empty-notes'                           : 'Hier sind noch keine Notizen zu finden.',
            'message-empty-todos'                           : 'Hier sind noch keine Todos zu finden.',
            'message-empty-students'                        : 'Hier sind noch keine Schüler:innen zu finden.',
            'message-empty-scorings'                        : 'Du hast noch keine Listen mit Benotungen oder Klassenarbeiten angelegt, die hier angezeigt werden könnten..',
            'paypal-error'                                  : 'Zahlung per PayPal nicht erfolgreich',
            'paypal-cancellation-error'                     : 'Du hast die Zahlung abgebrochen.',
            'paypal-generic-network-error'                  : 'Bei der Transaktion ist ein Netzwerkfehler aufgetreten: Bitte versuche es in einem Augenblick noch einmal.<br/><br/>%%param0%%',
            'paypal-misc-error'                             : 'Bei der Transaktion ist ein Fehler aufgetreten: Bitte versuche es in einem Augenblick noch einmal.<br/><br/>%%param0%%',
            'reset-shares'                                  : 'Teilen von Elementen beenden',
            'reset-shares-button'                           : 'Alle mit %%param0%% %%param1%% geteilten Elemente nicht mehr teilen',
            'unshare-all-title'                             : 'Bist du dir sicher?',
            'unshare-all-message'                           : 'Alle geteilten Elemente werden hierdurch bei deiner Kollegin / deinem Kollegen gelöscht und können nicht mehr gemeinsam bearbeitet werden.',
            'unshare-all-confirm'                           : 'Ja: Teilen beenden',
            'featureset-full'                               : '"Pro"-Version',
            'featureset-lite'                               : '"Basis"-Version',
            'featureset-networked'                          : '"Netzwerk"-Version',
            '___averageScoreCalculation'                    : '&oslash; Punkteschnitt',
            '___average'                                    : '&oslash; Durchschnitt',
            '___total'                                      : 'Gesamt',
            'added'                                         : 'hinzugefügt',
            'element'                                       : 'Element',
            'elements'                                      : 'Elemente',
            'elements-shared-with-me'                       : 'mit mir geteilte Elemente',
            'elements-shared'                               : 'von mir geteilte Elemente',
            'not-currently-shared-with-students'            : 'Das Element wird zur Zeit keiner Schülerin/keinem Schüler bereitgestellt.',
            'cancel-all-student-shares'                     : 'Bereitstellung mit allen Schüler:innen beenden',
            'cancel-student-share'                          : 'Bereitstellung beenden',
            'linked-elements'                               : 'verknüpfte Elemente',
            'caption-name'                                  : 'Name',
            'caption-action'                                : 'Aktion',
            'caption-share-with-students'                   : 'Schüler:innen wählen',
            'share-element-with-student'                    : '%%param0%% bereitstellen',
            'caption-blocker-sharing-with-students'         : 'Elemente werden bereitgestellt...',
            'message-blocker-sharing-with-students'         : 'Die gewählten Elemente werden den Schülerinnen und Schülern bereitgestellt: Augenblick bitte...',
            'caption-blocker-cancel-sharing-with-students'  : 'Bereitstellung wird beendet...',
            'message-blocker-cancel-sharing-with-students'  : 'Die gewählten Elemente werden den Schülerinnen und Schülern nicht mehr bereitgestellt: Augenblick bitte...',
            'unknown-user'                                  : 'Nutzer:in unbekannt',
            'general-right-marker'                          : '(generelle Berechtigung)',
            'archive-marker'                                : '(Archiv)',
            'element_s'                                     : 'Element%%param0%%',
            'select-page'                                   : 'Seite wählen',
            'search-placeholder'                            : 'Wonach suchst du? (mindestens %%param0%% Zeichen)',
            'search-no-results'                             : 'Es gibt noch keine Ergebnisse zu deiner Suchanfrage',
            'default-caption-empty'                         : 'Hier gibt\'s (noch) nichts zu sehen',
            'message-empty-shares'                          : 'Das wird sich ändern, sobald du Elemente mit %%param0%% geteilt hast.',
            'message-empty-shared-with-me'                  : 'Das wird sich ändern, sobald %%param0%% Elemente mit dir geteilt hat.',
            'default-message-empty-templates'               : 'Es gibt keine Listen, die als Vorlage verwendbar wären.',
            'media-gallery'                                 : 'Deine Dokumente',
            'template-gallery'                              : 'Vorlagen-Galerie',
            'headline-colleague-general-rights'             : 'Generelle Berechtigungen für geteilte Elemente',
            'headline-colleague-element-rights'             : 'Effektive Berechtigungen für geteilte Elemente',
            'headline-colleague-shared-with-me'             : 'Effektive Berechtigungen für mit mir geteilte Elemente',
            'button-caption-set-general-rights-for-all'     : '<strong>Änderungen für alle Kolleg:innen übernehmen</strong>',
            'caption-colleague-reset-element-rights'        : 'Alle Berechtigungen für geteilte %%param0%% zurücksetzen',
            'rights-are-being-reset'                        : 'Die Element-Berechtigungen werden zurückgesetzt...',
            'caption-all-filtered'                          : 'Ooops: Alles weggefiltert!',
            'message-all-filtered'                          : 'Du hast keine Elemente, die zu deinen Filtereinstellungen passen: Schade.',
            'route-login'                                   : 'Login: Anmeldung an entzettelt',
            'route-demo'                                    : 'Testnutzer: Unsere Demoversion',
            'route-dashboard'                               : 'Dashboard: Startseite',
            'route-students'                                : 'Schüler:innen',
            'route-classes'                                 : 'Klassen',
            'route-groups'                                  : 'Gruppen',
            'route-office'                                  : 'Büro',
            'route-colleagues'                              : 'Kollegium',
            'route-notes'                                   : 'Notizen',
            'route-todos'                                   : 'Todos',
            'route-planner'                                 : 'Kalender',
            'route-settings'                                : 'Einstellungen',
            'route-messages'                                : 'Postfach',
            'object-type-postbox'                           : 'Postfach',
            'field-type-checkbox'                           : 'Checkbox',
            'field-type-textbox'                            : 'Textzeile',
            'field-type-rateselector'                       : 'Bewertung',
            'field-type-textarea'                           : 'Freitext',
            'field-type-datebox'                            : 'Datum',
            'field-type-numberbox'                          : 'Zahl',
            'field-type-rateplantselector'                  : 'Feedback-Element "Pflanze"',
            'field-type-ratesmileselector'                  : 'Feedback-Element "Smileys" | :-( bis :-D',
            'field-type-ratesmileselectorreverse'           : 'Feedback-Element "Smileys" | :-D bis :-(',
            'field-type-scoreBox'                           : 'Benotung',
            'field-type-threewaytoggle'                     : 'Ja / Nein / Keine Angabe',
            'field-type-studentData'                        : 'Schülerdaten',
            'field-type-testItem'                           : 'Teilaufgabe einer Klassenarbeit',
            'field-type-testComment'                        : 'Kommentarspalte einer Klassenarbeit',
            'color-mint'                                    : 'mint',
            'color-orange'                                  : 'orange',
            'color-white'                                   : 'weiß',
            'color-blue'                                    : 'blau',
            'color-yellow'                                  : 'gelb',
            'color-red'                                     : 'rot',
            'color-pink'                                    : 'pink',
            'color-green'                                   : 'grün',
            'color-grey'                                    : 'grau',
            'from-until'                                    : 'vom <strong>%%param0%%</strong> bis zum <strong>%%param1%%</strong>',
            'school-holidays'                               : 'Ferien',
            'holiday'                                       : 'Feiertag',
            'multiday-event'                                : 'mehrtägiges Ereignis',
            'date-on'                                       : 'am',
            'no-description'                                : 'keine Beschreibung',
            'birthday'                                      : 'Geburtstag',
            '******************************* TEILEN ******' : '************************************************',
            'share-with-students-options'                   : 'Optionen',
            'no-options-for-share-with-students'            : 'nicht verfügbar',
            '***************************** UI CLASS ******' : '************************************************',
            'ui-blocker-software-update'                    : 'entzettelt wird aktualisiert...',
            'ui-blocker-software-update-text'               : '<strong>Eine neuere Version unserer App wird installiert</strong>.<br/><br/>Das dauert nicht lange: Versprochen!',
            'ui-blocker-software-update-text-2'             : '<strong>Das Update wurde vorbereitet!</strong><br/><br/>In 5 Sekunden geht\'s weiter...',
            '******************************** LOGIN ******' : '************************************************',
            'login-blocker-title'                           : 'Anmeldung läuft',
            'login-blocker-error'                           : 'Ooops!',
            'login-blocker-text-challenge-1'                : 'Challenge beantworten...',
            'login-blocker-text-challenge-2'                : 'Challenge wurde beantwortet',
            'login-blocker-text-challenge-error'            : 'Challenge wurde abgelehnt!',
            'login-blocker-text-keyimport'                  : 'Wir importieren deine Schlüssel...',
            'login-blocker-text-keyimport-error'            : 'Dein Schlüsselbund konnte nicht importiert werden!',
            'login-blocker-text-keyimport-error-catch'      : 'Dein Schlüsselbund konnte nicht importiert werden! (Fehler: %%param0%%)',
            'login-blocker-text-uuid'                       : 'Geräte-UUID wird geprüft...',
            'login-blocker-text-unlocking-key'              : 'Dein Schlüsselbund wird entsperrt...',
            'login-blocker-text-branch-mismatch'            : 'Du hast keine Berechtigung, diese Version von entzettelt zu nutzen!',
            'login-blocker-text-error-unlock'               : 'Dein Schlüsselbund konnte nicht entsperrt werden!',
            'login-blocker-text-token-validation'           : 'Tokenvalidierung wird durchgeführt...',
            'login-blocker-text-token-valid'                : 'Token akzeptiert',
            'login-blocker-text-token-invalid'              : 'Token nicht akzeptiert: Versuch\'s nochmal!',
            'login-blocker-text-offline-validation'         : 'Du bist zur Zeit offline: Die Anmeldung findet ohne Serververbindung statt...',
            'login-message-unsynced-entries'                : 'Es liegen noch nicht synchronisierte Inhalte vor: Ein Nutzerwechsel würde diese unwiderruflich löschen...',
            'login-message-database-preparation'            : 'Die Datenbank wird vorbereitet...',
            'login-message-offline-userchange'              : 'Du bist zur Zeit offline: Ein Nutzerwechsel kann nur bei bestehender Internet-Verbindung durchgeführt werden',
            'login-blocker-title-success'                   : 'Alles paletti',
            'login-blocker-body-success'                    : 'Gleich geht\'s weiter...',
            'login-blocker-database-reset'                  : 'Die Datenbank wird zurückgesetzt...',
            'logout-title-session-timeout'                  : 'Du wurdest automatisch abgemeldet',
            'logout-body-session-timeout'                   : 'Du warst zu lange inaktiv. Deine Sitzung wurde daher aus Sicherheitsgründen beendet. Bitte melde dich erneut an, um weiter mit <strong>entzettelt</strong> arbeiten zu können.',
            'logout-blocker-title'                          : 'Abmeldung',
            'logout-blocker-text-session-timeout'           : 'Du warst zu lange inaktiv. Deine Sitzung wird aus Sicherheitsgründen beendet.',
            'logout-blocker-text-goodbye'                   : 'Bis bald!',
            'logout-blocker-text-cleanup'                   : 'Wir räumen hier noch flott auf...',
            '******************************** FORMS ******' : '************************************************',
            'form-method-add'                               : 'erstellen',
            'form-method-create'                            : 'erstellen',
            'form-method-forward'                           : 'weiterleiten',
            'form-method-answer'                            : 'beantworten',
            'form-method-edit'                              : 'bearbeiten',
            'color'                                         : 'Farbe',
            'title'                                         : 'Bezeichnung',
            'datestart'                                     : 'gültig ab',
            'dateend-optional'                              : 'gültig bis (optional)',
            'dateend'                                       : 'gültig bis',
            'open-end'                                      : '-',
            'classReference'                                : 'zu Klasse',
            'groupReference'                                : 'zu Gruppe',
            'yeargroupReference'                            : 'zu Jahrgang',
            'placeholder_generic_description'               : 'gib hier eine Beschreibung ein',
            'placeholder_generic_title'                     : 'gib hier eine Bezeichnung ein',
            'placeholder_competence_title'                  : 'gib hier die Bezeichnung der (Teil-)kompetenz ein',
            'placeholder_competence_description'            : 'gib hier eine Formulierung ein',
            'placeholder_competencecategory_title'          : 'gib hier eine Bezeichnung für den Kompetenzbereich ein',
            'placeholder_competencecategory_description'    : 't:placeholder_generic_description',
            'placeholder_date_title'                        : 't:placeholder_generic_title',
            'placeholder_date_start'                        : 'z.B. 10:00',
            'placeholder_date_end'                          : 'z.B. 12:00',
            'placeholder_date_description'                  : 'gib hier weitere Details zu deinem Termin an',
            '****************************** BUTTONS ******' : '************************************************',
            'button-title-add'                              : 'Neues Element anlegen',
            'button-title-edit'                             : 'bearbeiten',
            'button-title-row-add'                          : 'Eintrag hinzufügen',
            'button-title-row-remove'                       : 'Eintrag entfernen',
            'button-title-select'                           : 'Mehrere Elemente auswählen',
            'button-title-restore'                          : 'Wiederherstellung starten',
            'button-title-add-large'                        : 'Neues Element anlegen',
            'button-title-list-opener'                      : 'aufklappen',
            'button-title-list-closer'                      : 'zuklappen',
            'button-title-list-detail-opener'               : 'auf-/zuklappen',
            'button-title-clone-for-today'                  : 'neue Version für heute erzeugen',
            'button-title-show-for-today'                   : 'heutige Version anzeigen',
            'button-title-calendar'                         : 'Auswahl über Kalender',
            'button-title-move'                             : 'Element verschieben',
            'button-title-print'                            : 'Ansicht als PDF exportieren',
            'button-title-excel'                            : 'Ansicht als Excel-Tabelle exportieren',
            'button-title-scores'                           : 'Auswertung anzeigen',
            'button-title-averages'                         : 'Durchschnitte anzeigen',
            'button-title-sums'                             : 'Details / Summen anzeigen',
            'button-title-summary'                          : 'Zusammenfassung anzeigen',
            'button-title-vertical'                         : 'Spalten untereinander anzeigen',
            'button-title-vertical-on'                      : 'Spalten untereinander anzeigen',
            'button-title-vertical-off'                     : 'Standard-Ansicht',
            'button-title-close'                            : 'schließen',
            'button-title-share'                            : 'teilen',
            'button-title-options'                          : 'erweiterte Einstellungen',
            'button-title-search'                           : 'Suche',
            'button-title-delete'                           : 'löschen',
            'button-title-archive'                          : 'archivieren',
            'button-title-unarchive'                        : 'reaktivieren',
            'button-title-history'                          : 'historische Bearbeitungsstände anzeigen',
            'button-title-export'                           : 'Kalenderdatei exportieren',
            'button-title-show-hidden'                      : 'versteckte Elemente anzeigen',
            'button-title-show-hidden-active'               : 'versteckte Elemente ausblenden',
            'button-title-organizer-contents'               : 'Planungsreihen verwalten',
            '*************************** NETWORKING ******' : '************************************************',
            'invite-is-being-accepted'                      : 'Die Einladung wird angenommen...',
            'invite-accepted'                               : 'Ihr seid nun vernetzt',
            'invite-accepted-message'                       : 'Bis dein Kollegium synchronisiert wurde können noch ein paar Sekunden vergehen.',
            '******************************** LISTS ******' : '************************************************',
            'list-type-customFixed'                         : 'Flex-Liste',
            'list-type-ratinglist'                          : 'Einfache Bewertungsliste',
            'list-type-checklist'                           : 'Einfache Checkliste',
            'list-type-test'                                : 'Klassenarbeit',
            'list-type-recallList'                          : 'Rücklaufliste',
            'list-type-referenceList'                       : 'Zuordnungsliste',
            'list-type-combilist'                           : 'Kombi-Liste',
            'list-open-another-list'                        : 'Eine weitere Liste öffnen',
            'list-editor-closing-message'                   : '<strong>Augenblick bitte...</strong><br/>Liste wird geschlossen',
            'list-row-is-being-removed'                     : 'Die Zeile wird gelöscht...',
            'list-filter-no-matches'                        : 'Mit den gewählten Filtereinstellungen wurden leider keine Daten gefunden.',
            'lists-count-elements'                          : '<strong>%%param0%%</strong> Elemente',
            'lists-count-filterInfo'                        : ' insgesamt (davon <strong>%%param0%%</strong> %%param1%% ausgefüllt)',
            '***************************** SETTINGS ******' : '************************************************',
            'push_not_supported_title'                      : 'Schade!',
            'push_not_supported_message'                    : 'Diese Funktion von entzettelt wird von deinem Gerät (noch) nicht unterstützt.<br/>Das kann sich allerdings schnell ändern: Schau einfach in ein paar Tagen noch einmal nach.',
            'profile_avatar_html'                           : '<p>Hier kannst du dein Profilbild, auch Avatar genannt, ändern. Dieses Bild ist nur für dich und dein\n' +
                                                              'Kollegium sichtbar und kann selbst vom entzettelt-Support nicht entschlüsselt werden.</p>\n' +
                                                              '<p>Deinen Avatar kannst du entweder mit der App "frisch" aufnehmen oder ein auf deinem Gerät vorhandenes\n' +
                                                              'Bild verwenden:</p>',
            'delete_profile_html'                           : '<p>Das finden wir zwar sehr schade, doch heißt es auch "Reisende soll man nicht aufhalten".<br/>\n' +
                                                              'Um Dein Profil vollständig zu löschen, bestätige deinen Wunsch bitte durch einen Klick auf die\n' +
                                                              'Schaltfläche "Profil endgültig löschen".</p>\n' +
                                                              '<p><strong>Beachte bitte</strong>, dass dieser Schritt nicht rückgängig gemacht werden kann. Deine Daten und\n' +
                                                              'das dazugehörige Schlüsselmaterial werden vollständig gelöscht und alle Inhalte, die du ggf. mit\n' +
                                                              'Kollegen geteilt hast, unbrauchbar.</p>',
            '***************************** IMPORTER ******' : '************************************************',
            'title-demo-files-for-import'                   : 'Brauchst du eine Beispieldatei?',
            'message-demo-files-for-import'                 : 'Als Vorlage für eine importierbare Datei haben wir etwas für dich vorbereitet:<br/><ul><li><a href="https://www.entzettelt.de/assets/import.xlsx" target="_blank">Beispieldatei im Microsoft Excel-Format</a></li><li><a href="https://www.entzettelt.de/assets/import.csv" target="_blank">Beispieldatei im CSV-Format</a></li></ul>Bei einem Klick auf einen der beiden Links lädst du eine Beispieldatei herunter, wie sie hier in der App nutzbar ist.<br/>Du kannst sie als Vorlage nutzen, oder sie zum Vergleich mit eventuell vorhandenen Schüler:innenlisten verwenden.',
            '************************** COMPETENCES ******' : '************************************************',
            'no-competence-data-found'                      : '<span class="comment">noch keine Daten vorhanden</span>',
            '****************************** PLANNER ******' : '************************************************',
            'title-planning-for'                            : 'Planung für %%param0%%',
            'planner-organizer-caption-classes-filter'      : 'Allgemeine Planung',
            'object-type-organizer-single'                  : 'Planungsraster',
            'tab-planner-setup-slots'                       : 'Tageseinteilung',
            'tab-planner-setup-week'                        : 'Wochenplanung',
            'tab-planner-setup-manage'                      : 'Übersicht',
            'organizer-generic'                             : 'Planung',
            'organizer-contents'                            : 'Planungs- / Unterrichtsreihen',
            'object-type-organizer'                         : 'Planungsvorlage',
            'object-type-organizers'                        : 'Planungsvorlagen',
            'object-type-organizers-single'                 : 't:object-type-organizer',
            'object-type-organizercontent'                  : 'Planungs- / Unterrichtsreihe',
            'object-type-organizercontents'                 : 'Planungs- / Unterrichtsreihen',
            'object-type-organizercontent-single'           : 't:object-type-organizercontent',
            'modalDeleteText-organizer'                     : 'Bist Du Dir sicher, dass du die Planungsvorlage <strong>%%title%%</strong> löschen möchtest?',
            'object-type-organizercontents-single'          : 't:object-type-organizercontent',
            'message-empty-organizer-content'               : 'Das kannst du natürlich ändern: Lege einfach eine neue Planungs- oder Unterrichtsreihe an, indem du auf das <strong>Plus</strong>-Symbol oben rechts klickst',
            'object-type-organizerslot-single'              : 'Slot',
            'modal-delete-title-slotmark'                   : 'Slot löschen',
            'organizer-contents-are-being-created'          : 'Deine Planungsreihe wird angelegt',
            'organizerstart'                                : 'von',
            'organizerend'                                  : 'bis',
            'organizer-notimerange'                         : 'ohne Zeitraum',
            'organizer-global'                              : 'globale Planung',
            'organizer-slots'                               : 'Planungsraster',
            'organizer-content-useParentColor'              : 'Farbe der Planung übernehmen',
            'organizer-content-slot-title'                  : 'Bezeichnung der Einheit',
            'organizer-content-slot-length'                 : 'Anzahl',
            'organizer-content-slots'                       : 'Planungs- / Unterrichtseinheiten',
            'modalDeleteText-organizercontent'              : 'Bist Du Dir sicher, dass du die Reihe <strong>%%title%%</strong> löschen möchtest?',
            'modal-delete-text-slotmark'                    : 'Bist du dir sicher, dass du den Slot <strong>%%param0%%</strong> löschen möchtest?',
            'title-planner-setup-slots'                     : 'Tageseinteilung',
            'title-planner-setup-week'                      : 'Planungsraster',
            'title-planner-setup-preview'                   : 'Vorschau',
            'title-modal-reset-slots'                       : 'Tageseinteilung zurücksetzen',
            'element-is-being-moved'                        : '%%param0%% wird verschoben',
            'text-modal-reset-slots'                        : 'Bist du dir sicher, dass du die Tageseinteilung zurücksetzen möchtest? <strong>Alle Einstellungen gehen hierbei verloren!</strong>',
            'info-planner-setup-slots'                      : 'Hier teilst du deinen Schultag in Einheiten (Slots) ein.<br/>Sobald du fertig bist, bestätige deine Änderungen durch einen Klick auf "<strong>speichern</strong>", um die Einteilung zu aktivieren.',
            'info-planner-setup-preview'                    : 'Hier siehst du, wie dein Planungsraster im Kalender aussehen wird',
            'info-planner-setup-week'                       : 'Hier richtest du deine Wochenplanung ein:<br/>Gib deiner Planung einen Namen und beschrifte die einzelnen Feldern in deinem Planungsraster. Farben kannst du natürlich auch vergeben.<br/>Deine Planungsvorlage bleibt immer so lange aktiv, bist du eine neue Vorlage erstellt hast.',
            'title-planner-setup-active-setup'              : 'Aktuell aktive Tageseinteilung',
            'error-no-organizers'                           : 'Planung für Zeitraum & Klasse oder Gruppe fehlt',
            '******************************* ERRORS ******' : '************************************************',
            'error-caption-offline'                         : 'Du bist offline',
            'error-message-offline-help'                    : '<strong>Unsere Hilfe-Seiten sind offline leider nicht verfügbar.</strong><br/>Bitte stelle zunächst eine Internetverbindung her und versuche es erneut.',
            '********************************* PUSH ******' : '************************************************',
            'push-reminder'                                 : 'Erinnerung an einen Termin',
            'push-reminder-body'                            : 'Denk an deinen Termin "%%param0%%", der %%param1%% stattfindet.',
            'push-reminder-button-1-caption'                : 'Kalender',
            'push-reminder-button-2-caption'                : 'ausblenden',
            'push-birthday'                                 : '%%param0%% hat heute Geburtstag!',
            'push-birthday-body'                            : 'Du wolltest an den heutigen Geburtstag von %%param0%% erinnert werden.',
            'push-birthday-button-1-caption'                : 'Schüler:innen',
            'push-birthday-button-2-caption'                : 'ausblenden',
            'push-recall'                                   : '%%param0%% ist heute fällig!',
            'push-recall-body'                              : 'Deine Rücklaufliste "%%param0%%" ist heute fällig: Denk daran, sie auszufüllen.',
            'push-recall-button-1-caption'                  : 'Büro',
            'push-recall-button-2-caption'                  : 'ausblenden',
            'push-todo'                                     : '%%param0%% ist heute fällig!',
            'push-todo-body'                                : 'Dein ToDo "%%param0%%" wolltest du heute erledigt haben.',
            'push-todo-button-1-caption'                    : 'Todos',
            'push-todo-button-2-caption'                    : 'ausblenden',
            '************************** HELP-BUTTON ******' : '************************************************',
            'recommend'                                     : 'entzettelt weiterempfehlen',
            'feedback'                                      : 'Feedback',
            'tutorial'                                      : 'Einführung',
            'help'                                          : 'Hilfe',
            '*************************** LIST-SETUP ******' : '************************************************',
            'list-setup-use-template'                       : 'Vorlage verwenden',
            'list-setup-use-freestyle'                      : 'Neue Liste anlegen',
            '****************************** GENERIC ******' : '************************************************',
            'date'                                          : 'Datum',
            '**************************** TUTORIALS ******' : '************************************************',
            'tutorial-end'                                  : 'Einführung beenden',
            'tutorial-caption-notfound'                     : '<strong>Oh nein</strong>: Die Einführung für diesen Bereich ist noch nicht fertig',
            'tutorial-text-notfound-1'                      : 'Das tut uns sehr leid!<br/>Schau einfach in einer späteren Version von %%ez%% noch einmal vorbei.<br/><br/>Wir aktualisieren die App ständig und so kann es nicht mehr lange dauern, bis wir dir auch hier eine passende Einführung anbieten können.',
            'tutorial-caption-dashboard'                    : '<strong>Einführung</strong>: Die Startseite',
            'tutorial-text-dashboard-1'                     : 'Dies ist die Startseite von %%ez%%:<br/>Von hier aus hast du Zugriff auf alle Bereiche der App.<br/>Wenn du mehr über die einzelnen Bereiche wissen möchtest, klicke auf "Einführung starten".<br/>Die Einführung lässt sich jederzeit über den "Schließen"-Button (X) beenden.',
            'tutorial-text-dashboard-2'                     : 'Über das "Schüler:innen"-Icon erreichst du den gleichnamigen Bereich, in dem du deine Schüler:innen anlegen & verwalten kannst.<br/>Schüler:innen stellen bei %%ez%% die Grundlage aller Elemente dar: Nur mit Schüler:innen hast du später die Möglichkeit, Klassen, Gruppen, Listen und Klassenarbeiten anzulegen.',
            'tutorial-text-dashboard-3'                     : 'Um deine Schüler:innen Klassen zuzuweisen, klickst du das "Klassen"-Icon an: In diesem Bereich kannst du Klassen anlegen & verwalten.<br/>In unserer "Pro"-Version steht dir zudem ein "Gruppen"-Bereich zur Verfügung, mit dem du deine Schüler:innen zusätzlich in Gruppen organisieren kannst.',
            'tutorial-text-dashboard-4'                     : 'Das "Büro" ist das Herzstück von %%ez%%: Die Heimat der Listen und Klassenarbeiten.<br/>Hier findet die eigentliche Dokumentationsarbeit statt.<br/>In unserer "Pro"-Version stehen dir weiterhin ein "Notizen"- und ein "Todo"-Bereich zur Verfügung, in dem du individuelle Notizen und Todo-Listen anlegen und verwalten kannst.',
            'tutorial-text-dashboard-5'                     : 'Im "Kalender" findest du alle Elemente kalendarisch sortiert wieder.<br/>Wahlweise in einer Wochen- oder Monatsansicht kannst du dir einen schnellen Überblick verschaffen, Termine planen oder gezielt Elemente für ein bestimmtes Datum (auch rückwirkend) anlegen.',
            'tutorial-text-dashboard-6'                     : 'Das virtuelle "Kollegium" lässt dich mit deinen Kolleg:innen gemeinsam arbeiten. Hast du Kolleg:innen, die auch %%ez%% nutzen? Dann kannst du sie hier in dein Kollegium einladen und Berechtigungen für geteilte Elemente verwalten.',
            'tutorial-text-dashboard-7'                     : 'Mit dem "Postfach" kannst du dich mit deinem Kollegium austauschen und Nachrichten versenden und empfangen.<br/>Hin und wieder kann es vorkommen, dass wir dich über Postfach über Neuigkeiten rund um %%ez%% informieren.',
            'tutorial-text-dashboard-8'                     : '<strong>Last, but not least</strong>: Im Einstellungsbereich kannst du %%ez%% ganz nach deinem Geschmack konfigurieren. Eine solide Grundeinstellung ist zwar von Anfang an vorhanden, doch kann es sein, dass du eine andere Sortierung bevorzugst, Farbkategorien erstellen oder eine Lizenz kaufen möchtest. All das findest du hier.',
            'tutorial-caption-students'                     : '<strong>Einführung</strong>: Verwalten von Schüler:innendaten',
            'tutorial-text-students-1'                      : 'Hier verwaltest du deine Schüler- und Schülerinnendaten.<br/>Du kannst Schüler:innen anlegen und verwalten und hast jederzeit schnellen Zugriff auf die gesamte Dokumentation.<br/>Wenn du mehr über die Funktionsweise erfahren möchtest, klicke auf "Einführung starten".',
            'tutorial-text-students-2'                      : '<strong>Um neue Schüler:innen anzulegen</strong>, verwende das "+"-Symbol.<br/>Immer dann, wenn du etwas anlegen kannst, findest du dieses Symbol im rechten, oberen Bereich des Bildschirms.<br/><br/><strong>Klicke es an, um fortzufahren...</strong>',
            'tutorial-text-students-3'                      : 'In diesem Formular legst du eine Schülerin oder einen Schüler an.<br/>Fülle aus, was du ausfüllen magst: Ausser dem Vor- und Nachnamen gibt es keine Pflichtfelder. <strong>Während dieser Einführung füllt sich das Formular freundlicherweise selbständig aus.</strong><br/><br/>Klicke auf "weiter" um fortzufahren.',
            'tutorial-text-students-4'                      : 'Wenn du einige Schüler:innen angelegt hast und schnell nach jemandem suchen musst, hilft dir unsere <strong>Volltextsuche</strong> sicherlich weiter: Hiermit kannst du alle Seiten, die das Lupensymbol haben, schnell und effektiv durchsuchen.',
            'tutorial-text-students-5'                      : 'Du möchtest mehrere Elemente gleichzeitig löschen oder mit deinem Kollegium teilen?<br/>Hinter diesem Symbol verbirgt sich unsere <strong>Mehrfachauswahl</strong>. Mit einem Klick wird sie aktiviert. Danach kannst du alle Elemente anklicken, die du löschen oder teilen möchtest und die gewünschte Aktion mit Hilfe der entsprechenden Buttons ausführen, die nach einer Auswahl eingeblendet werden.',
            'tutorial-text-students-6'                      : 'Über die praktischen Filter behältst du jederzeit die Übersicht. Mit dem Klassen- & Gruppenfilter kannst du die Liste nach Klassen oder Gruppen filtern.',
            'tutorial-text-students-7'                      : 'Mit dem farbigen Schüler:innen-Filter wird die Liste nach Geschlecht gefiltert.<br/><strong>Hinweis</strong>: In den Einstellungen von %%ez%% kannst du für deine Schüler:innen auch das allgemeine Farbschema aktivieren. Dann lassen sich Schüler:innen auch nach Farbe filtern.',
            'tutorial-text-students-8'                      : 'Über die Reiter kannst du wählen, ob du deine eigenen Elemente, mit dir geteilte Elemente oder archivierte Elemente sehen möchtest.<br/>In den Einstellungen kannst du auch festlegen, ob geteilte und eigene Elemente in einem Reiter zusammengefasst dargestellt werden sollen.<br/>Nach einem Schuljahreswechsel oder der Archivierung von Elementen findest du diese im jeweiligen Archiv-Reiter wieder.',
            'tutorial-text-students-9'                      : 'Und so sieht beispielsweise ein Schülerdatensatz aus: "<strong>Fritz Walter</strong>" ist in diesem Beispiel Teil der Klasse <strong>2a</strong>.<br/>Wäre dies ein richtiger Datensatz könntest du ihn anklicken und würdest alle Elemente sehen, die diesem Schüler zugeordnet sind.<br/>Um den Datensatz zu bearbeiten oder zu löschen, schiebst du das Element mit dem Finger nach links oder - am PC - klickst mit der rechten Maustaste darauf:<br/><strong>Probiere das einmal aus, um fortzufahren</strong>...',
            'tutorial-text-students-10'                     : 'Hier findest du nun Buttons, mit denen du Elemente bearbeiten, löschen oder archivieren kannst. Wenn du bereits ein Kollegium angelegt hast, findest du an dieser Stelle auch einen Button zum Teilen von Elementen.',
            'tutorial-text-students-11'                     : 'Der <strong>Bleistift</strong> öffnet das Element zum Bearbeiten',
            'tutorial-text-students-12'                     : 'Mit dem <strong>roten "X"-Button</strong> kannst du das Element vollständig löschen. Löschst du einen Schüler oder eine Schülerin, werden auch alle zugehörigen Einträge in Listen gelöscht.',
            'tutorial-text-students-13'                     : 'Mit der <strong>Archivmappe</strong> kannst du das Element in dein Archiv verschieben: Im Archiv bleibt es sichtbar und Einträge in zugehörigen Listen bleiben bestehen.',
            'tutorial-text-students-14'                     : 'Nun hast du gelernt, wie man die Grundfunktionen nutzen und <strong>Schüler:innen</strong> mit %%ez%% anlegen und verwalten kann.',
            'tutorial-caption-classes'                      : '<strong>Einführung</strong>: Verwalten von Klassen',
            'tutorial-text-classes-1'                       : 'Hier verwaltest du deine Klassen.<br/>Du kannst Klassen anlegen und verwalten und hast jederzeit schnellen Zugriff auf die gesamte Dokumentation.<br/>Wenn du mehr über die Funktionsweise erfahren möchtest, klicke auf "Einführung starten".',
            'tutorial-text-classes-2'                       : '<strong>Um eine neue Klasse anzulegen</strong>, verwende das "+"-Symbol.<br/>Immer dann, wenn du etwas anlegen kannst, findest du dieses Symbol im rechten, oberen Bereich des Bildschirms.<br/><br/><strong>Klicke es an, um fortzufahren...</strong>',
            'tutorial-text-classes-3'                       : 'In diesem Formular legst du eine Klasse an.<br/>Eine Klasse braucht einen Namen (z.B. "3a" oder "Phoenix"), eine Farbe und natürlich Schüler:innen. <strong>Während dieser Einführung füllt sich das Formular freundlicherweise selbständig aus.</strong><br/><br/>Klicke auf "weiter" um fortzufahren.',
            'tutorial-text-classes-4'                       : 'Wenn du einige Klassen angelegt hast und schnell nach einer bestimmten Klasse suchen musst, hilft dir unsere <strong>Volltextsuche</strong> vielleicht weiter: Hiermit kannst du alle Seiten, die das Lupensymbol haben, schnell und effektiv durchsuchen.',
            'tutorial-text-classes-5'                       : 'Du möchtest mehrere Elemente gleichzeitig löschen oder mit deinem Kollegium teilen?<br/>Hinter diesem Symbol verbirgt sich unsere <strong>Mehrfachauswahl</strong>. Mit einem Klick wird sie aktiviert. Danach kannst du alle Elemente anklicken, die du löschen oder teilen möchtest und die gewünschte Aktion mit Hilfe der entsprechenden Buttons ausführen, die nach einer Auswahl eingeblendet werden.',
            'tutorial-text-classes-6'                       : 'Mit dem farbigen Filter wird die dargestellte Liste nach den ausgewählten Farben gefiltert.',
            'tutorial-text-classes-7'                       : 'Über die Reiter kannst du wählen, ob du deine eigenen Elemente, mit dir geteilte Elemente oder archivierte Elemente sehen möchtest.<br/>In den Einstellungen kannst du auch festlegen, ob geteilte und eigene Elemente in einem Reiter zusammengefasst dargestellt werden sollen.<br/>Nach einem Schuljahreswechsel oder der Archivierung von Elementen findest du diese im jeweiligen Archiv-Reiter wieder.',
            'tutorial-text-classes-8'                       : 'Und so sieht beispielsweise eine Klasse aus: Die Klasse "<strong>2a</strong>" hat in diesem Beispiel 24 Schüler:innen.<br/>Wäre dies ein richtiger Datensatz könntest du ihn anklicken und würdest alle Elemente sehen, die dieser Klasse zugeordnet sind.<br/>Um den Datensatz zu bearbeiten oder zu löschen, schiebst du das Element mit dem Finger nach links oder - am PC - klickst mit der rechten Maustaste darauf:<br/><strong>Probiere das einmal aus, um fortzufahren</strong>...',
            'tutorial-text-classes-9'                       : 'Hier findest du nun Buttons, mit denen du Elemente bearbeiten, löschen oder archivieren kannst. Wenn du bereits ein Kollegium angelegt hast, findest du an dieser Stelle auch einen Button zum Teilen von Elementen.',
            'tutorial-text-classes-10'                      : 'Der <strong>Bleistift</strong> öffnet das Element zum Bearbeiten',
            'tutorial-text-classes-11'                      : 'Mit dem <strong>roten "X"-Button</strong> kannst du das Element vollständig löschen. Löschst du eine Klasse, werden auch alle zugehörigen Listen gelöscht.',
            'tutorial-text-classes-12'                      : 'Mit der <strong>Archivmappe</strong> kannst du das Element in dein Archiv verschieben: Im Archiv bleibt es sichtbar und zugehörige Listen bleiben bestehen.',
            'tutorial-text-classes-13'                      : 'Nun hast du gelernt, wie man die Grundfunktionen nutzen und <strong>Klassen</strong> mit %%ez%% anlegen und verwalten kann.',
            'tutorial-caption-groups'                       : '<strong>Einführung</strong>: Verwalten von Gruppen',
            'tutorial-text-groups-1'                        : 'Hier verwaltest du deine Gruppen.<br/>Du kannst Gruppen anlegen und verwalten und hast jederzeit schnellen Zugriff auf die gesamte Dokumentation.<br/>Wenn du mehr über die Funktionsweise erfahren möchtest, klicke auf "Einführung starten".',
            'tutorial-text-groups-2'                        : '<strong>Um eine neue Gruppe anzulegen</strong>, verwende das "+"-Symbol.<br/>Immer dann, wenn du etwas anlegen kannst, findest du dieses Symbol im rechten, oberen Bereich des Bildschirms.<br/><br/><strong>Klicke es an, um fortzufahren...</strong>',
            'tutorial-text-groups-3'                        : 'In diesem Formular legst du eine Gruppe an.<br/>Eine Gruppe braucht einen Namen (z.B. "Fördergruppe"), eine Farbe und natürlich Schüler:innen. <strong>Während dieser Einführung füllt sich das Formular freundlicherweise selbständig aus.</strong><br/><br/>Klicke auf "weiter" um fortzufahren.',
            'tutorial-text-groups-4'                        : 'Wenn du einige Gruppen angelegt hast und schnell nach einer bestimmten Gruppe suchen musst, hilft dir unsere <strong>Volltextsuche</strong> vielleicht weiter: Hiermit kannst du alle Seiten, die das Lupensymbol haben, schnell und effektiv durchsuchen.',
            'tutorial-text-groups-5'                        : 'Du möchtest mehrere Elemente gleichzeitig löschen oder mit deinem Kollegium teilen?<br/>Hinter diesem Symbol verbirgt sich unsere <strong>Mehrfachauswahl</strong>. Mit einem Klick wird sie aktiviert. Danach kannst du alle Elemente anklicken, die du löschen oder teilen möchtest und die gewünschte Aktion mit Hilfe der entsprechenden Buttons ausführen, die nach einer Auswahl eingeblendet werden.',
            'tutorial-text-groups-6'                        : 'Mit dem farbigen Filter wird die dargestellte Liste nach den ausgewählten Farben gefiltert.',
            'tutorial-text-groups-7'                        : 'Über die Reiter kannst du wählen, ob du deine eigenen Elemente, mit dir geteilte Elemente oder archivierte Elemente sehen möchtest.<br/>In den Einstellungen kannst du auch festlegen, ob geteilte und eigene Elemente in einem Reiter zusammengefasst dargestellt werden sollen.<br/>Nach einem Schuljahreswechsel oder der Archivierung von Elementen findest du diese im jeweiligen Archiv-Reiter wieder.',
            'tutorial-text-groups-8'                        : 'Und so sieht beispielsweise eine Gruppe aus: Die "<strong>Fördergruppe Mathe</strong>" hat in diesem Beispiel 7 Schüler:innen.<br/>Wäre dies ein richtiger Datensatz könntest du ihn anklicken und würdest alle Elemente sehen, die dieser Gruppe zugeordnet sind.<br/>Um den Datensatz zu bearbeiten oder zu löschen, schiebst du das Element mit dem Finger nach links oder - am PC - klickst mit der rechten Maustaste darauf:<br/><strong>Probiere das einmal aus, um fortzufahren</strong>...',
            'tutorial-text-groups-9'                        : 'Hier findest du nun Buttons, mit denen du Elemente bearbeiten, löschen oder archivieren kannst. Wenn du bereits ein Kollegium angelegt hast, findest du an dieser Stelle auch einen Button zum Teilen von Elementen.',
            'tutorial-text-groups-10'                       : 'Der <strong>Bleistift</strong> öffnet das Element zum Bearbeiten',
            'tutorial-text-groups-11'                       : 'Mit dem <strong>roten "X"-Button</strong> kannst du das Element vollständig löschen. Löschst du eine Gruppe, werden auch alle zugehörigen Listen gelöscht.',
            'tutorial-text-groups-12'                       : 'Mit der <strong>Archivmappe</strong> kannst du das Element in dein Archiv verschieben: Im Archiv bleibt es sichtbar und zugehörige Listen bleiben bestehen.',
            'tutorial-text-groups-13'                       : 'Nun hast du gelernt, wie man die Grundfunktionen nutzen und <strong>Gruppen</strong> mit %%ez%% anlegen und verwalten kann.',
            'tutorial-caption-notes'                        : '<strong>Einführung</strong>: Notizen',
            'tutorial-text-notes-1'                         : 'Hier machst du deine Notizen.<br/>Du kannst Notizen verfassen und sie Schüler:innen, Klassen und Gruppen zuordnen.<br/>Wenn du mehr über die Funktionsweise erfahren möchtest, klicke auf "Einführung starten".',
            'tutorial-text-notes-2'                         : '<strong>Um eine neue Notiz anzulegen</strong>, verwende das "+"-Symbol.<br/>Immer dann, wenn du etwas anlegen kannst, findest du dieses Symbol im rechten, oberen Bereich des Bildschirms.<br/><br/><strong>Klicke es an, um fortzufahren...</strong>',
            'tutorial-text-notes-3'                         : 'In diesem Formular legst du nun eine Notiz an.<br/>Eine Notiz besteht minimal aus mindestens einer Notiz und einer Farbe. <strong>Während dieser Einführung füllt sich das Formular freundlicherweise selbständig aus.</strong><br/><br/>Klicke auf "weiter" um fortzufahren.',
            'tutorial-text-notes-4'                         : 'Um weitere Optionen der Notiz festzulegen, verwendest du das <strong>Zahnrad</strong>: Ein Klick darauf öffnet die erweiterten Funktionen. So kannst du deine Notiz einer Schülerin oder einem Schüler, einer Klasse oder einer Gruppe zuordnen. Um die Notiz vor den neugierigen Blicken zu schützen, aktiviere zusätzlich die "geschützt"-Checkbox.<br/><br/><strong>Hinweis:</strong> Wenn du beispielsweise eine Notiz zu "Fritz Walter" aus der Klasse "2a" verfasst, die sich nur auf Fritz bezieht, wähle die Klasse hier nicht aus, da sie sonst auch immer in der jeweiligen Klassenansicht angezeigt wird.',
            'tutorial-text-notes-5'                         : 'Und so sieht eine Notiz aus.',
            'tutorial-text-notes-6'                         : 'So stellt sich eine Notiz dar, die du mit aktivierter "geschützt"-Checkbox angelegt hast. Um sie lesbar zu machen, klicke einfach auf ihren Text. Um sie wieder zu "schützen", klicke erneut darauf.',
            'tutorial-text-notes-7'                         : 'Um die Notiz zu bearbeiten oder zu löschen, schiebst du das Element mit dem Finger nach links oder - am PC - klickst mit der rechten Maustaste darauf:<br/><strong>Probiere das einmal aus, um fortzufahren</strong>...',
            'tutorial-text-notes-8'                         : 'Hier findest du nun Buttons, mit denen du Elemente bearbeiten, löschen oder archivieren kannst. Wenn du bereits ein Kollegium angelegt hast, findest du an dieser Stelle auch einen Button zum Teilen von Elementen.',
            'tutorial-text-notes-9'                         : 'Der <strong>Pin</strong> heftet die Notiz an den Anfang der Notizen-Liste',
            'tutorial-text-notes-10'                        : 'Der <strong>Bleistift</strong> öffnet das Element zum Bearbeiten',
            'tutorial-text-notes-11'                        : 'Mit dem <strong>roten "X"-Button</strong> kannst du das Element vollständig löschen.',
            'tutorial-text-notes-12'                        : 'Mit der <strong>Archivmappe</strong> kannst du das Element in dein Archiv verschieben: Im Archiv bleibt es sichtbar.',
            'tutorial-text-notes-13'                        : 'Nun hast du gelernt, wie man die Grundfunktionen nutzen und <strong>Notizen</strong> mit %%ez%% anlegen und verwalten kann.',
            'tutorial-caption-todos'                        : '<strong>Einführung</strong>: Todos',
            'tutorial-text-todos-1'                         : 'Hier hast du die Möglichkeit, Todo-Listen zu pflegen.<br/>Du kannst neue Todos anlegen und sie Schüler:innen, Klassen und Gruppen zuordnen.<br/>Wenn du mehr über die Funktionsweise erfahren möchtest, klicke auf "Einführung starten".',
            'tutorial-text-todos-2'                         : '<strong>Um ein neues Todo anzulegen</strong>, verwende das "+"-Symbol.<br/>Immer dann, wenn du etwas anlegen kannst, findest du dieses Symbol im rechten, oberen Bereich des Bildschirms.<br/><br/><strong>Klicke es an, um fortzufahren...</strong>',
            'tutorial-text-todos-3'                         : 'In diesem Formular legst du nun ein Todo an, das du erledigen möchtest.<br/>Ein Todo besteht mindestens aus einer Aufgabe, die du erledigen möchtest, einem Fälligkeitstermin und einer Farbe. Weitere Details sind optional.<br/><strong>Während dieser Einführung füllt sich das Formular freundlicherweise selbständig aus.</strong><br/><br/>Klicke auf "weiter" um fortzufahren.',
            'tutorial-text-todos-4'                         : 'Um weitere Optionen festzulegen, verwendest du das <strong>Zahnrad</strong>: Ein Klick darauf öffnet die erweiterten Funktionen. So kannst du das Todo einer Schülerin oder einem Schüler, einer Klasse oder einer Gruppe zuordnen. Auch kann ein Todo weitere Teilaufgaben enthalten, die erledigt werden sollten, um das Todo abzuschließen.<br/><br/><strong>Hinweis:</strong> Wenn du beispielsweise ein Todo zu "Fritz Walter" aus der Klasse "2a" verfasst, die sich nur auf Fritz bezieht, wähle die Klasse hier nicht aus, da sie sonst auch immer in der jeweiligen Klassenansicht angezeigt wird.',
            'tutorial-text-todos-5'                         : 'Und so sieht ein Todo aus.<br/>Hast du unter "Details" Informationen oder Teilaufgaben eingetragen, kannst du diese durch einen Klick anzeigen lassen.',
            'tutorial-text-todos-6'                         : 'Um das Todo zu bearbeiten oder zu löschen, schiebst du das Element mit dem Finger nach links oder - am PC - klickst mit der rechten Maustaste darauf:<br/><strong>Probiere das einmal aus, um fortzufahren</strong>...',
            'tutorial-text-todos-7'                         : 'Hier findest du nun Buttons, mit denen du Elemente bearbeiten, löschen oder archivieren kannst. Wenn du bereits ein Kollegium angelegt hast, findest du an dieser Stelle auch einen Button zum Teilen von Elementen.',
            'tutorial-text-todos-8'                         : 'Der <strong>Pin</strong> heftet das Todo an den Anfang der Todo-Liste',
            'tutorial-text-todos-9'                         : 'Der <strong>Bleistift</strong> öffnet das Element zum Bearbeiten',
            'tutorial-text-todos-10'                        : 'Mit dem <strong>roten "X"-Button</strong> kannst du das Element vollständig löschen.',
            'tutorial-text-todos-11'                        : 'Mit der <strong>Archivmappe</strong> kannst du das Element in dein Archiv verschieben: Im Archiv bleibt es weiterhin sichtbar.',
            'tutorial-text-todos-12'                        : 'Nun hast du gelernt, wie man die Grundfunktionen nutzen und <strong>Todos</strong> mit %%ez%% anlegen und verwalten kann.',
            'tutorial-caption-office'                       : '<strong>Einführung</strong>: Büro',
            'tutorial-text-office-1'                        : 'Im Büro verwaltest du Listen & Klassenarbeiten.<br/>Du kannst neue Listen anlegen und sie Klassen und Gruppen zuordnen.<br/>Wenn du mehr über die Funktionsweise erfahren möchtest, klicke auf "Einführung starten".',
            'tutorial-text-office-2'                        : '<strong>Um eine neue Liste anzulegen</strong>, verwende das "+"-Symbol.<br/>Immer dann, wenn du etwas anlegen kannst, findest du dieses Symbol im rechten, oberen Bereich des Bildschirms.<br/><br/><strong>Klicke es an, um fortzufahren...</strong>',
            'tutorial-text-office-3'                        : 'Alles startet mit der Auswahl des Listentypen:<br/>Hier wählst du zunächst aus, was für eine Liste du benötigst, oder ob du eine Klassenarbeit anlegen möchtest.',
            'tutorial-text-office-4'                        : 'Dir stehen diverse Listentypen zur Verfügung: einfache Check- oder Bewertungslisten, Rücklauflisten oder Klassenarbeiten.<br/>In unserer Pro-Version stehen dir zusätzlich frei definierbare Flex-Listen oder Kombi-Listen zur Verfügung. Da jeder Listentyp unterschiedliche Felder benötigt, ändert sich das Formular mit der Auswahl des Listentypen automatisch.<br/><br/><strong>Klicke auf "weiter", um einige Beispiele zu sehen</strong>',
            'tutorial-text-office-5'                        : 'Hier siehst du, wie sich das Formular bei der Auswahl des Listentyps automatisch ändert',
            'tutorial-text-office-6'                        : 'Auch hast du die Möglichkeit, Vorlagen von %%ez%% zu verwenden. Wir haben einige Vorlagen für dich vorbereitet, damit dir der Einstieg leichter fällt.<br/><strong>Übrigens:</strong> Selbst angelegte Listen stehen dir hier automatisch als Vorlage zur Verfügung, sofern du es in den Einstellungen der App nicht deaktiviert hast.<br/><br/><strong>Klicke auf "weiter", um einige Beispiele zu sehen</strong>',
            'tutorial-text-office-7'                        : 'Hier siehst du, wie sich das Formular bei der Auswahl einer Vorlage automatisch ändert',
            'tutorial-text-office-8'                        : 'Und so sehen Listen in der Regel aus.<br/>In diesem Beispiel ist es eine Anwesenheitsliste, die mit Hilfe einer "einfachen Checkliste" erzeugt wurde.',
            'tutorial-text-office-9'                        : 'Die Funktionsbuttons am oberen Rand einer jeden Liste bieten dir diverse Möglichkeiten:<br/>Der Button zum <strong>Aufklappen</strong> öffnet die Details eine Liste.',
            'tutorial-text-office-10'                       : 'Mit dem <strong>markierten Kalenderblatt</strong> rufst du die "heutige" Version einer Liste auf. Alle Listen (ausser Klassenarbeiten und Rücklauflisten) lassen sich für jedes Datum duplizieren. So kannst du denkbar einfach beispielsweise tägliche Anwesenheitslisten erzeugen. Wenn noch keine Liste für den aktuellen Tag vorliegt, erscheint an dieser Stelle ein "<strong>+</strong>"-Symbol.',
            'tutorial-text-office-11'                       : 'Mit dem <strong>Modus</strong>-Button kannst du durch die verschiedenen Ansichten einer Liste wechseln:<br/>Es gibt hier die <strong>Zusammenfassung</strong> (Listensymbol, Standardansicht) für die verschiedenen Tage, eine <strong>Summenansicht</strong> (Summenzeichen) für alle Schüler:innen und eine <strong>vertikale Auflistung</strong> (Pfeil nach unten) für alle Schüler:innen, in der die Spalten untereinander statt nebeneinander dargestellt werden, zur Verfügung.',
            'tutorial-text-office-12'                       : 'Mit dem <strong>Kalenderblatt</strong> kannst du die Liste für ein beliebiges Datum duplizieren. Wenn du beispielsweise vergessen hast, eine Anwesenheitsliste für den 1. März zu erzeugen, kannst du über den Kalender diese Version anlegen und die Anwesenheiten nachtragen.',
            'tutorial-text-office-13'                       : 'In der <strong>Pro-Version</strong> von %%ez%% findest du hier noch Schaltflächen, mit denen du eine Listenansicht als PDF oder Excel-Datei exportieren kannst.',
            'tutorial-text-office-14'                       : 'Um eine Liste zu bearbeiten oder zu löschen, schiebst du das Element mit dem Finger nach links oder - am PC - klickst mit der rechten Maustaste darauf:<br/><strong>Probiere das einmal aus, um fortzufahren</strong>...',
            'tutorial-text-office-15'                       : 'Hier findest du nun Buttons, mit denen du Elemente bearbeiten, löschen oder archivieren kannst. Wenn du bereits ein Kollegium angelegt hast, findest du an dieser Stelle auch einen Button zum Teilen von Elementen.',
            'tutorial-text-office-16'                       : 'Der <strong>Pin</strong> heftet die Liste an den Anfang der Seite',
            'tutorial-text-office-17'                       : 'Der <strong>Bleistift</strong> öffnet das Element zum Bearbeiten',
            'tutorial-text-office-18'                       : 'Der <strong>Statistik</strong>-Button öffnet die Listen als grafische Auswertung. So lassen sich die verschiedenen Stände von Listen optisch miteinander vergleichen und Entwicklungen darstellen.',
            'tutorial-text-office-19'                       : 'Mit dem <strong>roten "X"-Button</strong> kannst du das Element vollständig löschen.',
            'tutorial-text-office-20'                       : 'Mit der <strong>Archivmappe</strong> kannst du das Element in dein Archiv verschieben: Im Archiv bleibt es weiterhin sichtbar.',
            'tutorial-text-office-21'                       : 'Nun hast du gelernt, wie man die Grundfunktionen nutzen und <strong>Listen & Klassenarbeiten</strong> mit %%ez%% anlegen und verwalten kann.',
            '**************** FUNCTIONAL FORMFIELDS ******' : '************************************************',
            'formField:moveToClass'                         : 'in Klasse verschieben',
            '**************************** PREHEATER ******' : '************************************************',
            'preheating-captions-old'                       : [ 'denkt nach...',
                                                                'sortiert Farben...',
                                                                'bestellt schönes Wetter...',
                                                                'findet Schüler...',
                                                                'findet Schülerinnen...',
                                                                'hat noch nicht fertig...',
                                                                'gruppiert Klassen...',
                                                                'kalkuliert Schnittmengen...',
                                                                'teilt durch Null...',
                                                                'holt schnell \'nen Kaffee...',
                                                                'ist sicherererer...',
                                                                'sucht Termine...',
                                                                'sortiert Notizen...',
                                                                'zählt Erbsen...',
                                                                'grübelt noch...',
                                                                'freut sich auf die Ferien...' ],
            'preheating-captions'                           : [ 'Setze * * um Wörter, um sie <strong>fett</strong> zu schreiben',
                                                                'Setze _ _ um Wörter, um sie zu <u>unterstreichen</u>',
                                                                'Mit dem <strong>+</strong> kannst du eine Liste für das aktuelle Datum erzeugen: So hast du Momentaufnahmen von einer Liste für mehrere Tage.',
                                                                'Wenn du in einem Text Zeilen mit einem - beginnst, wird daraus eine einfache Liste',
                                                                'Im Bereich Einstellungen kannst du alle Benotungssysteme aktivieren, die du benötigst.',
                                                                'Wenn dir ein spezielles Benotungssystem fehlt, melde dich über die Feedback-Funktion bei uns.',
                                                                'Wenn dir die Farben zu blass erscheinen, kannst du in den Einstellungen ein kräftigeres Farblayout verwenden.',
                                                                'entzettelt läuft zwar im Browser, funktioniert aber auch <strong>offline</strong>.',
                                                                'Wenn du Fragen zu entzettelt hast, kannst du dich jederzeit über die Feedback-Funktion bei uns melden: Wir antworten garantiert.',
                                                                'Bei manchen Elementen findest du ein Zahnrad-Symbol. Dahinter verbergen sich eine Vielzahl weiterer Funktiionen. Klicke die Zahnrädchen einfach mal an ;-)',
                                                                'entzettelt gibt es auch als Schulversion, damit das ganze Kollegium zusammen arbeiten kann',
                                                                'Wir lieben Feedback! Wenn dir eine bestimmte Funktionalität fehlt, melde dich gerne bei uns: Vielleicht finden wir schon bald eine Lösung! ' ]
        }
    }

}