<template>
    <div v-if="undefined !== item && false !== item"
         :class="'touchable'+( galleryMode ? ' gallery' : '' )">
        <Quickmarks :item
                    :noShareIndicator
                    :overrideQueueType
                    :scope/>
        <div :class="'touchable-left'+( swiped ? ' swiped' : '' )+' '+additionalCssClasses"
             :id="'touchable-'+viewItem+'-'+scope+'-'+item.localId"
             :key="renderKey">
            <div class="dragbutton sortable"
                 :id="'dragbutton-'+item.localId"
                 v-if="sortable === true">
            </div>
            <div :class="sortable ? 'dragspaced-touchable' : ''">
                <DebugInfo v-if="$core.settings().getSetting( 'debugInfo' )"
                           :item="item"/>
                <component :is="component"
                           :item
                           :selected
                           :selectMode
                           :escapeNested
                           :singleItems
                           :inListEditor
                           :fakeCount
                           @unswipe="handleUnswipe"
                           @prepared="prepared = true"
                           @dragging="$emit( 'dragging', $event )"
                           @scrolling="handleIsScrolling"
                           @findTab="$emit( 'findTab', $event )"
                           @injectHandlers="handleInjectedHandlers"
                           @clicked="handleClick"
                           @dataUpdate="handleDataUpdate"
                           @contextmenu="handleSwipe"/>
            </div>
        </div>
        <FunctionButtons :item="item"
                         :viewItem="viewItem"
                         :scope="scope"
                         :swipedRight="swipedRight"
                         :disappearing="functionsDisappearing"
                         @clicked="handleFunctionClick"/>
        <div class="clearfix"></div>
    </div>
    <div v-else></div>
</template>

<script>
/* eslint-disable */
import Quickmarks                from "@/components/defaults/defaultTouchable/Quickmarks";
import DebugInfo                 from "@/components/defaults/defaultTouchable/DebugInfo";
import FunctionButtons           from "@/components/defaults/defaultTouchable/FunctionButtons";
import MixinSwipeHandler         from "@/components/defaults/defaultTouchable/MixinSwipeHandler";
import MixinEvents               from "@/mixins/MixinEvents";
import MixinFunctionClickHandler from "@/components/defaults/defaultTouchable/MixinFunctionClickHandler";
import MixinSelectHandler        from "@/components/defaults/defaultTouchable/MixinSelectHandler";
import MixinDevHelper            from "@/mixins/MixinDevHelper";

export default {

    name      : "Touchable",
    components: {
        FunctionButtons,
        DebugInfo,
        Quickmarks
    },
    mixins    : [ MixinSwipeHandler, MixinFunctionClickHandler, MixinSelectHandler, MixinEvents, MixinDevHelper ],
    emits     : [ 'refresh', 'clicked', 'select', 'answer', 'forward', 'dragging', 'findTab', 'requestUpdate' ],

    props: {
        itemZoom         : { Type: Object, required: false },
        localId          : { Type: String, required: true },
        viewItem         : { Type: String, required: true },
        overrideQueueType: { Type: String, required: false },
        scope            : { Type: String, required: true },
        controlKey       : { Type: String, required: false, default: '' },
        clickDisabled    : { Type: Boolean, required: false, default: false },
        bare             : { Type: Boolean, required: false, default: false },
        escapeNested     : { Type: Boolean, required: false, default: false },
        selectMode       : { Type: Boolean, required: false, default: false },
        singleItems      : { Type: Boolean, required: false, default: false },
        startOpen        : { Type: Boolean, required: false, default: false },
        fakeElement      : { Type: Boolean, required: false, default: false },
        noShareIndicator : { Type: Boolean, required: false, default: false },
        galleryMode      : { Type: Boolean, required: false, default: false },
        inListEditor     : { Type: Boolean, required: false, default: false },
        noRerender       : { Type: Boolean, required: false, default: false },
        sortable         : { Type: Boolean, required: false, default: false },
        dragging         : { Type: Boolean, required: false, default: false }
    },

    data()
    {
        return {
            item                 : undefined,
            injectedHandlers     : false,
            positionChanged      : false,
            scrolling            : false,
            swiped               : false,
            swipedRight          : false,
            functionsDisappearing: false,
            additionalCssClasses : '',
            lastClick            : 0,
            selected             : false,
            prepared             : false,
            timer                : false,
            lastElementKey       : false,
            lastContentKey       : false,
            renderKey            : false,
            flashTimeout         : null,
            fakeCount            : undefined
        }
    },

    watch: {
        controlKey: {
            immediate: true,
            handler( newValue, oldValue )
            {
                if( newValue !== oldValue
                    && undefined !== oldValue
                    && false === this.$props.noRerender )
                {
                    let item = this.getReferenceItem()
                    if( undefined !== item )
                    {
                        if( item.elementKey !== this.lastElementKey
                            && item.contentKey !== this.lastContentKey )
                        {

                            this.lastElementKey = item.elementKey
                            this.lastContentKey = item.contentKey

                            clearTimeout( this.flashTimeout )
                            this.flashTimeout = setTimeout( () =>
                            {
                                this.renderKey = newValue
                            }, 1000 )

                            this.item = item
                        }
                    }
                }
            }
        }
    },

    computed: {
        component()
        {
            return this.$props.viewItem
        }
    },

    beforeUnmount()
    {
        this.item = undefined
    },

    created()
    {

        this.createReferenceItem()
        if( undefined === this.item )
        {
            return
        }

        this.addEvent( 'refresh-touchable-' + this.item.localId, () =>
        {
            this.createReferenceItem()
            this.lastElementKey = this.item.elementKey
        } )

        this.addIndexedEvent( 'on-deselect-all', () =>
        {
            this.selected = false
        } )
        this.addIndexedEvent( 'on-select-' + this.item.localId, ( state ) =>
        {
            this.selected = state
        } )

        this.lastElementKey = this.item.elementKey

    },

    methods: {

        createReferenceItem()
        {
            this.item = this.getReferenceItem()
            this.fakeCount = this.item.fakeCount || undefined
        },

        getReferenceItem()
        {
            if( undefined !== this.$props.itemZoom )
            {
                if( 'list' === this.$props.itemZoom.type )
                {
                    let item      = this.$core.f().deref( this.$props.itemZoom ),
                        container = this.$core.getBaseClassHelper()
                                        .get( 'list' )
                                        .getContainer( this.$props.localId, undefined, true )
                    item.lists = [ this.$props.itemZoom ]
                    item._tc = container._tc
                    return item
                }
                return this.$props.itemZoom
            }
            else
            {
                let item
                switch( this.$core.f().ucFirst( this.$props.viewItem ) )
                {
                    case 'List':
                        return this.$core.getBaseClassHelper()
                                   .get( 'list' )
                                   .getContainer( this.$props.localId, undefined, true )
                        break
                    default:
                        item = this.$core.getBaseClassHelper()
                                   .getObjectById( this.$props.localId ) || {}
                        if( 0 === Object.keys( item ).length )
                        {
                            item = undefined
                            this.$core.getEventManager().dispatch( 'on-undef-touchable' )
                        }
                        return item
                        break
                }
            }
        },

        reset()
        {
            this.handleSwipe( false, 'right' )
            this.swiped = false
            this.swipedRight = false
            this.resetSwipes()
        },

        handleDataUpdate()
        {

            this.createReferenceItem()
            this.additionalCssClasses = 'data-updated shine'
            this.$core.getUi()
                .delay( () =>
                {

                    this.additionalCssClasses = ''

                }, 4000 )

        },

        handleClick( localId )
        {

            if( true === this.$props.dragging )
            {
                return
            }

            if( true === this.$props.inListEditor )
            {
                this.$emit( 'clicked', localId )
                return
            }

            if( !this.$props.clickDisabled
                && this.$core.f().now() > this.lastClick + 500 )
            {

                this.lastClick = this.$core.f().now()
                if( false === this.$props.selectMode )
                {
                    this.$emit( 'clicked' )
                }
                else
                {
                    this.selected = !this.selected
                    this.$emit( 'select', this.item.localId, this.selected )
                }

            }

        },

        handleIsScrolling()
        {

            this.scrolling = true
            if( false !== this.timer )
            {
                this.$core.getCoreTimer()
                    .removeTimeout( this.timer )
            }
            this.timer = this.$core.getUi()
                             .delay( () =>
                             {
                                 this.scrolling = false
                             }, 500 )

        },

        handleInjectedHandlers( handlers )
        {
            this.injectedHandlers = handlers
        }

    }

}
</script>