export default class SyncCrudSharedStudentAccesses
{

    constructor( parent )
    {

        this.p = parent
        this.parent = this.p.parent
        this.logger = this.parent.logger

        this.eventManager = this.parent.eventManager
        this.baseClassHelper = null
        this.shareHelper = null
        this.studentAccessHelper = null

        this.eventManager.append( 'on-baseclasses-available', baseClassHelper =>
        {
            this.baseClassHelper = baseClassHelper
            this.ready = this.baseClassHelper !== null && this.shareHelper !== null && this.studentAccessHelper !== null
        } )

        this.eventManager.append( 'on-share-ready', shareHelper =>
        {
            this.shareHelper = shareHelper
            this.ready = this.baseClassHelper !== null && this.shareHelper !== null && this.studentAccessHelper !== null
        } )

        this.eventManager.append( 'on-student-access-ready', studentAccessHelper =>
        {
            this.studentAccessHelper = studentAccessHelper
            this.ready = this.baseClassHelper !== null && this.shareHelper !== null && this.studentAccessHelper !== null
        } )

        this._logSign = 'SyncWorker::SyncCrud::SyncCrudSharedStudentAccesses [SCCSA]'
        this.lastRun = -1
        this.logger.clog( this._logSign, 'initializing' )

        return this

    }

    shouldSync()
    {
        return this.ready && ( Date.now() - ( 10 * 60000 ) ) > this.lastRun
    }

    fetchAllStudents()
    {
        return new Promise( resolve =>
        {

            this.baseClassHelper
                .get( 'student' )
                .cacheHeatup()
                .then( () =>
                {

                    return resolve( this.baseClassHelper
                                        .get( 'student' )
                                        .getCache( 'cache' ) )

                } )

        } )
    }

    sync()
    {
        return new Promise( resolve =>
        {

            this.logger.clog( this._logSign, 'synchronizing shared student accesses...' )
            this.fetchAllStudents()
                .then( students =>
                {

                    let countColleagues = 0,
                        countShares     = 0

                    for( const [ localId, student ] of students )
                    {
                        let shareList = this.shareHelper.shareList( student )
                        if( 0 < shareList.length )
                        {

                            let accessId = this.studentAccessHelper.hasStudentAccess( localId, true )
                            if( accessId )
                            {

                                let access         = this.studentAccessHelper.getStudentAccess( localId ),
                                    colleagueUuids = []

                                for( let s in shareList )
                                {
                                    if( !this.shareHelper.sharedWith( access, shareList[ s ].uuid ) )
                                    {
                                        countColleagues++
                                        colleagueUuids.push( shareList[ s ].uuid )
                                    }
                                }

                                if( 0 < colleagueUuids.length )
                                {
                                    countShares++
                                    this.shareHelper.share( colleagueUuids, [ access ], [] )
                                }

                            }

                        }
                    }

                    this.logger.clog( this._logSign, 'shared', countShares, 'student accesses with', countColleagues, 'colleagues...' )
                    this.lastRun = Date.now()
                    return resolve()

                } )

        } )
    }
}