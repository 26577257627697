export default class afterAllStudentAccess
{

    constructor( core )
    {
        this.baseClassHelper = core.getBaseClassHelper()
    }

    /*eslint-disable*/
    _fetchDependencies( skipArchived )
    {

        return new Promise( resolve =>
        {

            this.studentClass = this.studentClass || this.baseClassHelper.get( 'student' )
            this.studentClass.getPreparedCache()
                .then( students =>
                {
                    return resolve( {

                        students: students

                    } )
                } )

        } )

    }

    _getFromCache( which, cache, localId )
    {

        let student = cache[ which ].cache.get( localId )
        if( student )
        {
            return student
        }

        student = cache[ which ].archive.get( localId )
        if( student )
        {
            return student
        }

    }

    afterFetch( accesses, skipArchived )
    {
        return new Promise( resolve =>
        {

            this._fetchDependencies( skipArchived )
                .then( students =>
                {

                    for( let s in accesses )
                    {

                        let student = this._getFromCache( 'students', students, accesses[ s ].studentLocalId )
                        if( student )
                        {
                            accesses[ s ].displayName = accesses[ s ].username + ' | ' + student.lastname + ', ' + student.firstname
                            accesses[ s ].htmlName = accesses[ s ].username + ' | <strong>' + student.lastname + '</strong>, ' + student.firstname
                            accesses[ s ].classId = student.classId
                            accesses[ s ].classname = student.classname
                            accesses[ s ].inGroups = student.inGroups
                            accesses[ s ].groupId = student.groupId
                            accesses[ s ].yeargroupname = student.yeargroupname
                            accesses[ s ].yeargroupId = student.yeargroupId
                        }
                        else
                        {
                            accesses[ s ].displayName = accesses[ s ].username + ' | unbekannt'
                            accesses[ s ].htmlName = accesses[ s ].username + ' | <strong>unbekannt</strong>'
                        }

                        if( true === accesses[ s ].archived )
                        {
                            accesses[ s ].displayName = 'archiviert: ' + accesses[ s ].displayName
                        }

                    }

                    return resolve( accesses )

                } )

        } )
    }

    afterUpdate( students, cache, skipArchived )
    {

        return new Promise( resolve =>
        {

            this.afterFetch( students, skipArchived )
                .then( result =>
                {

                    return resolve( result )

                } )

        } )

    }

}